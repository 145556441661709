import React, { useEffect, useRef } from 'react';
import { ShieldCheckIcon, GlobeAltIcon, ClockIcon, ChartBarIcon, SupportIcon, CloudIcon } from '@heroicons/react/solid';
import { FaHandsHelping, FaCloudUploadAlt, FaTools, FaShieldAlt } from 'react-icons/fa'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import dataSec from "../assets/images/datasecurity.webp";
import multiUsr from "../assets/images/multiUsr.webp"
import accdata from "../assets/images/accesdata.webp"
import dataBckp from "../assets/images/dataBackup.webp"
import relper from "../assets/images/relper.webp"
import techsuport from "../assets/images/techsup.webp"
import teamwrk from "../assets/images/teamwrk.webp"
import enterprise2 from "../assets/images/enterprise2.webp"
import etertprisefirst from "../assets/images/enterprisefirst.webp"
import enterprisesec from "../assets/images/enterprisesecu.webp"
import enterpriserem from "../assets/images/enterpriseremote.webp"
import enterpriseavl from "../assets/images/enterpriserelibilities.webp"
import posremote from "../assets/images/posremote.webp"
import posSecu from "../assets/images/possecurity.webp"
import posrel from "../assets/images/posrel.webp"
import posinteg from "../assets/images/postintegra.webp"
import poscost from "../assets/images/poscost.webp"
import addon from "../assets/images/qb/add-ons.webp"
import pos1 from "../assets/images/poshosting.webp"
import possup from "../assets/images/possuport.webp"
import adons1 from "../assets/images/addons1.webp"
import { Helmet } from 'react-helmet';

import { FaUser, FaUsers, FaHdd, FaMemory, FaAppStore } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const features = [
  {
    id: 1,
    name: 'Full-Service Bookkeeping',
    icon: ShieldCheckIcon,
    description: 'From accounts payable and receivable to monthly reconciliation, we take care of all your bookkeeping needs, ensuring your financial records are always up to date and audit-ready.',
    image: posremote,
  },
  {
    id: 2,
    name: 'Financial Reporting and Insights',
    icon: GlobeAltIcon,
    description: "Our team prepares detailed financial reports, providing you with insights into your company's financial health. We help you understand your cash flow, profitability, and areas of improvement to make better financial decisions.",
    image: posSecu,
  },
  {
    id: 3,
    name: 'QuickBooks Setup and Cleanup',
    icon: ClockIcon,
    description: "Whether you're new to QuickBooks or need assistance cleaning up existing data, we provide comprehensive setup and cleanup services. We'll help organize your financial data, set up custom charts of accounts, and ensure your QuickBooks is optimized for your business.",
    image: posrel,
  },
  {
    id: 4,
    name: 'Tax Preparation and Compliance',
    icon: ChartBarIcon,
    description: 'Stay compliant with tax regulations effortlessly. Our QuickBooks Accountant services include accurate tax preparation, ensuring you’re ready for tax season without the stress. We help you organize and categorize expenses to maximize deductions and minimize liabilities.',
    image: posinteg,
  },
  {
    id: 11,
    name: 'Payroll Processing',
    icon: SupportIcon,
    description: 'Our QuickBooks Accountant services include managing payroll with accuracy and timeliness. We handle payroll taxes, ensure compliance with labor laws, and manage employee payments without errors, giving you peace of mind.',
    image: teamwrk,
  },

];





const BenefitsSection = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
  const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white py-10">
      <div className="container mx-auto px-6">
        <h2 className={`text-3xl font-bold text-center mb-6 ${inView1 ? 'animate-fadeIn' : ''}`}>
          Benefits of Our QuickBooks Accountant Services
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div
            ref={ref1}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView1 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Save Time and Reduce Errors</h3>
            <p className="mb-4 card-info">
              Free up your valuable time by letting our experts manage your books. With our deep knowledge of QuickBooks, we reduce manual errors and improve the accuracy of your financial records.
            </p>
            <img
              src={possup}
              alt="Accessibility"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
            // style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref2}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView2 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Improved Decision-Making</h3>
            <p className="mb-4 card-info">
              Access reliable, up-to-date financial reports that help you make better, data-driven business decisions. Our QuickBooks experts provide you with a clear financial picture and valuable insights into your business performance.
            </p>
            <img
              src={poscost}
              alt="Backup and Recovery"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
            // style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref3}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView3 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Affordable and Flexible</h3>
            <p className="mb-4 card-info">
              We offer flexible packages that fit different business sizes and budgets. Whether you need ongoing monthly bookkeeping or one-time QuickBooks assistance, our services are affordable and scalable to your needs.
            </p>
            <img
              src={multiUsr}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
            // style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref4}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView4 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Expert Guidance and Support</h3>
            <p className="mb-4 card-info">
              Our team provides ongoing support and answers to any QuickBooks-related questions you may have. We help you navigate QuickBooks’ features and ensure you’re using the system effectively for your financial needs.
            </p>
            <img
              src={"https://quickbooks.intuit.com/oidam/intuit/sbseg/en_au/quickbooks-online/web/image/hero/sbseg-en_au-oa-retargeting-hero-rw--no-widget.png"}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
            // style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HostingSection = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 md:py-20 md:px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">
        {/* Header Section */}
        <div className="text-center mb-6 md:mb-16">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-3 md:mb-4">
            Take Control of Your Business Finances with Expert QuickBooks Accountant Support
          </h1>

        </div>
        {/* Content Section */}
        <div className="grid gap-10 md:gap-16 items-center grid-cols-1 md:grid-cols-2">
          {/* Text Content */}
          <div className="space-y-4 md:space-y-6">
            <p className="text-base md:text-xl text-gray-700 leading-relaxed">
              ConciseNext Solutions offers dedicated QuickBooks Accountant services designed to help businesses streamline their accounting processes, ensure accurate financial reporting, and maximize productivity. Our certified QuickBooks experts are here to make bookkeeping simple, insightful, and stress-free, giving you more time to focus on your core business operations.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-2">
                <ShieldCheckIcon className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <span className="font-semibold text-gray-800">Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-6 w-6 md:h-8 md:w-8 text-green-600" />
                <span className="font-semibold text-gray-800">Reliable</span>
              </div>
              <div className="flex items-center space-x-2">
                <CloudIcon className="h-6 w-6 md:h-8 md:w-8 text-purple-600" />
                <span className="font-semibold text-gray-800">Scalable</span>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="relative">
            <img
              src={addon}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-60 md:h-80"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuickBooksProHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          QuickBooks Add-ons Hosting Pricing & Plans
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At <span className="font-bold">ConciseNext</span>, we offer flexible hosting plans designed to meet the unique needs of businesses of all sizes. Whether you're an individual user or part of a growing team, our plans provide the performance, security, and scalability you need to keep your accounting operations running smoothly.
        </motion.p>
      </div>
    </section>
  );
};

const StandardHostingPlan = () => {
  const standardPlans = [
    {
      title: '1-User Plan',
      price: '$33',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-purple-100',
    },
    {
      title: 'Per Additional User',
      price: '$30',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-pink-100',
    }
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">
          {standardPlans.map(plan => (
            <motion.div
              key={plan.title}
              className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
                <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
                <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
              </div>
              <div className="flex items-center justify-center text-gray-600">
                <FaHdd className="text-blue-600 mr-2" />
                <span>{plan.storage}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};



const IndustriesWeServe = () => {
  return (
    <div className="max-w-6xl mx-auto p-6">

      <div className="text-center mt-12 border-1 p-4">
        <h3 className="text-3xl font-bold mb-4">
          Get Started with ConciseNext Solutions Today
        </h3>
        <p className="mb-8">
          Let ConciseNext Solutions transform the way you manage your finances. Our QuickBooks Accountant services provide you with the expert accounting support you need to keep your books organized and your business running smoothly.
        </p>
        <p className="mb-8">
          <b>Contact Us</b> today to find out how we can support your business and take your accounting to the next level with QuickBooks. With our help, managing your finances has never been easier!
        </p>
        <Link to={"/contact-us"} className="bg-button text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors">
          Contact Us
        </Link>
      </div>
    </div>
  );
};


const HowItWorks = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });


  const fadeInClass = (inView) =>
    inView ? 'opacity-100 translate-y-0 scale-100' : 'opacity-0 translate-y-8 scale-95';

  return (
    <div className="max-w-5xl mx-auto p-3">
      <h2 className="text-4xl font-bold text-center mb-8">
        Why Choose QuickBooks Accountant Services?
      </h2>

      <div className={`flex items-start mb-8 transition-transform duration-[1000ms] ${fadeInClass(inView1)}`} ref={ref1}>
        <FaHandsHelping className="text-4xl text-blue-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">1. Expert Financial Management</h3>
          <p>
            Our QuickBooks Accountant services are led by certified accounting professionals with years of experience. We help manage all aspects of your bookkeeping, including invoicing, expenses, bank reconciliation, payroll, and financial reporting, ensuring everything is handled with precision and efficiency.
          </p>
        </div>
      </div>

      <div className={`flex items-start mb-8 transition-transform duration-[1000ms] ${fadeInClass(inView2)}`} ref={ref2}>
        <FaCloudUploadAlt className="text-4xl text-green-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">2. Customized for Your Business Needs</h3>
          <p>
            We understand that every business is unique. Our QuickBooks Accountant services are customized to fit your specific needs, providing solutions tailored to your industry, growth stage, and financial objectives.
          </p>
        </div>
      </div>

      <div className={`flex items-start transition-transform duration-[1000ms] ${fadeInClass(inView3)}`} ref={ref3}>
        <FaTools className="text-4xl text-yellow-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold ">3. Real-Time Data Access</h3>
          <p>
            Get a clear view of your business finances in real-time. Our QuickBooks experts help you harness the full capabilities of QuickBooks, giving you the ability to track transactions, generate reports, and make informed business decisions based on up-to-date financial data.
          </p>
        </div>
      </div>

    </div>
  );
};


const AdditionalFeatures = () => {
  // Hook for triggering animations on scroll
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });


  const fadeInClass = (inView) =>
    inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8';

  return (
    <div className="max-w-5xl mx-auto p-6">
      <h2 className="text-4xl font-bold text-center mb-8">
        Who Can Benefit from QuickBooks Accountant Services?
      </h2>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView1)}`} ref={ref1}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">1. Small to Medium-Sized Businesses: </h3>
        <p className="mb-8">
          Business owners who need reliable accounting and bookkeeping support to help manage their finances accurately.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView2)}`} ref={ref2}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">2. Freelancers and Contractors</h3>
        <p className="mb-8">
          Independent professionals who want a hassle-free way to manage income, expenses, and prepare for tax season.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView3)}`} ref={ref3}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">3. Growing Businesses</h3>
        <p className="3">
          Businesses experiencing growth that need scalable, expert support to manage increasingly complex financial operations.
        </p>
      </div>

    </div>
  );
};


const SmoothScrollFeature = ({ feature, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  useEffect(() => {
    if (inView && window.innerWidth > 768) {  // Check if screen is larger than 768px
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={window.innerWidth > 768 ? controls : 'visible'}  // Disable animation on small screens
      variants={{
        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.7, ease: 'easeOut' }}
      className="grid md:grid-cols-2 gap-8 xl:gap-20 items-center py-8"
    >
      {/* Image */}
      <div className={`${index % 2 === 0 ? 'order-last md:order-first' : ''}`}>
        <img
          src={feature.image}
          alt={feature.name}
          className="rounded-lg shadow-lg transform hover:scale-105 h-[auto] w-[400px] xl:h-[auto] xl:w-[600px] transition duration-500"
        />
      </div>
      {/* Text */}
      <div>
        <h3 className="text-2xl font-bold text-gray-800 mb-4">{feature.name}</h3>
        <p className="text-lg text-gray-700 leading-relaxed">{feature.description}</p>
      </div>
    </motion.div>
  );
};

const Hero = () => {
  const { scrollY } = useScroll();
  const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
  const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
  const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
  const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <div className="relative h-[500px] md:h-[600px]  bg-[url('https://cdn.pixabay.com/photo/2014/12/27/15/40/office-581131_1280.jpg')] bg-cover bg-center bg-no-repeat overflow-hidden">
      {/* Background Circles Effect */}
      <div className="absolute flex items-center justify-center">
        <motion.div
          className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
          style={{ scale: scale1 }}
        />
        <motion.div
          className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
          style={{ scale: scale2 }}
        />
        <motion.div
          className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
          style={{ scale: scale3 }}
        />
      </div>

      {/* Hero Content */}
      <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
        <motion.h1
          className="text-3xl sm:text-4xl md:text-3xl font-bold mb-2 md:mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          QuickBooks Accountant Services by ConciseNext Solutions
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
          ConciseNext Solutions provides expert QuickBooks Accountant Services for precise bookkeeping and streamlined financial management.
        </motion.p>
        <motion.a
          href="#"
          className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
        >
          Start Your 7 Day Free Trial
        </motion.a>
      </div>



    </div>
  );
};

const QuickBooksAccountant = () => {




  return (
    <div>
      <Helmet>
        {/* Document Title */}
        <title>Cloud Based QuickBooks Accountaning Software | Quickbooks Accountant Desktop Version</title>

        {/* Meta Tags */}
        <meta name="title" content="Cloud Based QuickBooks Accountaning Software | Quickbooks Accountant Desktop Version" />
        <meta name="focus_keyword" content="Quickbook Accountant" />
        <meta name="description" content="ConciseNext offers powerful QuickBooks Accountant desktop version for accounting professionals. Experience cloud-based accounting software for accountants today!" />
        <meta name="keywords" content="concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management" />
        <meta name="robots" content="index, follow" />

        {/* Canonical Link */}
        <link rel="canonical" href={window.location.href} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="ConciseNext: Your Cloud Application Hosting Solution" />
        <meta property="og:description" content="Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs." />
        <meta property="og:image" content="https://concisenext.com/public_image/webp/logocolor.481479ea418411f3e450.webp" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Hero />
      <HostingSection />
      <div className="bg-white py-8">
        <div className="container mx-auto max-w-7xl">
          {features.map((feature, index) => (
            <SmoothScrollFeature key={feature.id} feature={feature} index={index} />
          ))}
        </div>
      </div>
      <BenefitsSection />
      <AdditionalFeatures />
      <HowItWorks />
      <IndustriesWeServe />

    </div>
  );
};

export default QuickBooksAccountant;
