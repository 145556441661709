import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaReact, FaExpand, FaPlaystation, } from 'react-icons/fa';
import { Helmet } from 'react-helmet';





export default function Sage500Hosting() {

    const SageHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative h-[500px] bg-[url('https://concisenext.com/public_image/webp/cloud-computing-concept-with-laptop_438099-26841.webp')] bg-cover bg-center bg-no-repeat  md:h-[600px] overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Sage 500 ERP Hosting With <span className='text-blue-600 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Unlock the full power of Sage 500 ERP with ConciseNext's advanced cloud hosting solutions. Manage your business operations with ease from anywhere, at any time, while enjoying top-tier security, scalability, and expert support. Elevate your business efficiency and streamline processes with our cutting-edge and dependable hosting services.

                    </motion.p>
                    <motion.a
                        href="/free-trial"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Start Your 7 Day Free Trial
                    </motion.a>
                </div>

                {/* Parallax Image */}
                <motion.img
                    src="https://concisenext.com/public_image/webp/logicgate-integrations-herobanner-500x497.webp"
                    alt="Sage 300"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                />
            </div>
        );
    };



    const SageIntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-8 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Sage 500 ERP Hosting Services - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-8 justify-center items-center">
                    <img
                        src="Website-NewHeaderCartoonforCEOPage-3723x3137px-EK-11-OCT-2023-V1-01-1.webp"
                        alt="Sage 50"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Take control of your business operations with ConciseNext’s Sage 500 ERP Hosting Services, designed to offer reliable, secure, and scalable cloud access to your Sage 500 ERP software. Our hosting solutions enable you to manage accounting, distribution, manufacturing, and financials remotely, while optimizing efficiency and reducing IT costs.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for Sage 500 ERP Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Remote Access Anywhere</h4>
                            <p className="text-lg text-gray-700">
                                Access your Sage 500 ERP software from any location on any device with our robust cloud hosting platform. Work on your business management tools whether you're in the office or working remotely, ensuring continuous productivity.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost Savings</h4>
                            <p className="text-lg text-gray-700">
                                Say goodbye to expensive on-premises servers and IT maintenance costs. Our Sage 500 hosting solutions offer a pay-as-you-go model, allowing you to enjoy cloud benefits without the large capital investment in hardware.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaReact className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Real-Time Collaboration</h4>
                            <p className="text-lg text-gray-700">
                                Enable multiple users to access and work on your Sage 500 ERP simultaneously. Our cloud hosting ensures team collaboration across different departments, improving workflow efficiency and data accuracy.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Advanced Security</h4>
                            <p className="text-lg text-gray-700">
                                With ConciseNext, your data is stored in highly secure, SOC-certified data centers. We use encryption, multi-layered firewalls, and regular backups to ensure the highest level of data protection for your Sage 500 ERP system.

                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const SageBenefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-8 p-8 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Benefits of Hosting Sage 500 ERP with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: '99.9% Uptime Guarantee',
                            description:
                                'Enjoy reliable performance and accessibility with our Sage 500 ERP hosting infrastructure. We ensure your business operations remain uninterrupted, minimizing downtime and maximizing productivity.',
                            imageUrl:
                                'https://concisenext.com/public_image/information-technology-banner-design-10-copy.webp',
                            link: '#',
                        },
                        {
                            title: 'Fully Managed Hosting',
                            description:
                                'Let us handle the technical complexities. Our fully managed hosting service includes server management, software updates, security patches, and daily backups, allowing you to focus on your core business activities.',
                            imageUrl:
                                'https://concisenext.com/public_image/Data-Backup.webp',
                            link: '#',
                        },

                        {
                            title: '24/7 Technical Support',
                            description:
                                'Our support team is available around the clock to assist with any technical issues or questions related to your hosted Sage 500 ERP environment. We ensure minimal disruption and quick resolutions.',
                            imageUrl:
                                'https://concisenext.com/public_image/images.webp',
                            link: '#',
                        },
                        {
                            title: 'Easy and Secure Migration',
                            description:
                                'Moving your Sage 500 ERP to the cloud is simple with our expert migration team. We ensure a seamless transition, safeguarding your data and minimizing downtime during the process.',
                            imageUrl:
                                'https://concisenext.com/public_image/technical-support-1.webp',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };



    const GetStartedSection = () => {
        return (
            <section className="relative bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 py-16 text-white">
                <div className="absolute inset-0 bg-black opacity-30"></div>
                <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-4xl font-bold mb-4">
                            Planning to Get Started with Hosted Sage 500 ERP?
                        </h2>
                        <p className="text-lg mb-8">
                            Explore the advantages of our cloud-hosted Sage 500 ERP solutions. Optimize your business processes and boost productivity with our premier hosting services, designed to deliver seamless performance and robust scalability.
                        </p>
                        <a
                            href="/free-trial"
                            className="bg-button text-white py-3 px-6 rounded-lg font-semibold text-lg transition-transform transform hover:scale-105 hover:bg-red-600"
                        >
                            Get Free Trial
                        </a>
                    </div>
                </div>
            </section>
        );
    };



    const SageHostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-10 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How Our Sage 500 ERP Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'Speak with our experts to discuss your business requirements. We’ll help you choose the best hosting solution for your Sage 500 ERP needs.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Migration',
                                description: 'Our team handles the secure migration of your Sage 500 ERP system and data to our cloud servers, ensuring a smooth transition with minimal business disruption.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Cloud Access',
                                description: 'Once hosted, you’ll enjoy remote access to your Sage 500 ERP system from any internet-enabled device, allowing your team to work seamlessly from any location',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Management',
                                description: 'We provide continuous monitoring, daily backups, and software updates to keep your Sage 500 ERP system running at optimal performance while maintaining security and data integrity.',
                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };

        return (
            <div className="bg-gray-50 py-9">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Businesses Choose ConciseNext for Sage 500 ERP Hosting
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Expertise in ERP Hosting",
                                description:
                                    "At ConciseNext, we specialize in hosting ERP systems like Sage 500, offering a tailored solution that fits your business needs and delivers consistent performance.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaExpand size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Scalable Solutions",
                                description:
                                    "Whether you’re a small business or a large enterprise, we offer customizable hosting plans to match your resource requirements. Easily scale your Sage 500 hosting as your business grows.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaPlaystation size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Uncompromised Security",
                                description:
                                    "We ensure your sensitive financial and operational data is secure at all times. Our cloud hosting environment meets strict security standards, including data encryption, multi-factor authentication, and proactive threat monitoring.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Streamlined IT Managementt",
                                description:
                                    "With ConciseNext, you won’t have to worry about maintaining servers, managing updates, or handling backups. We take care of everything, giving you peace of mind and the freedom to focus on your business.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaLaptop size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };

    const Sage50HostingPlans = () => {
        // Variants for scroll animation
        const variants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
        };

        return (
            <div className="max-w-7xl mx-auto p-8">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
                    Sage 500 ERP Hosting Plans
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Single-User Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Small Business Sage 500 ERP Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Ideal for small businesses looking for a secure, cloud-based ERP solution without the overhead costs of in-house IT management.
                        </p>
                        <a
                            href="/free-trial"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Start Your 7 Day Free Trial
                        </a>
                    </motion.div>
                    {/* Multi-User Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Mid-Sized Business Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Perfect for growing companies that need multi-user access and collaboration across departments, while maintaining a reliable and secure ERP environment.
                        </p>
                        <a
                            href="/free-trial"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Start Your 7 Day Free Trial
                        </a>
                    </motion.div>
                    {/* Custom Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Enterprise-Grade Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Designed for large organizations, our enterprise-level hosting solution offers advanced security, performance, and scalability for businesses with complex ERP needs.
                        </p>
                        <a
                            href="/free-trial"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Start Your 7 Day Free Trial
                        </a>
                    </motion.div>
                </div>
            </div>
        );
    };


    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-8 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Start Your Sage 500 ERP Cloud Journey with ConciseNext
                    </motion.h2>
                    <motion.p
                        className="text-lg text-gray-600 mb-8"
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
                    >
                        Upgrade your business efficiency with ConciseNext’s Sage 500 ERP hosting. Enjoy the freedom, security, and scalability that comes with moving your Sage 500 ERP to the cloud. Contact us today for a customized hosting plan that meets your business requirements.
                    </motion.p>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (817) 803-6797</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – Delivering secure, scalable, and reliable Sage 500 ERP hosting solutions.
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };





    return (
        <div>
            <Helmet>
                {/* Document Title */}
                <title>Sage 500 ERP Hosting Provider | Sage 500 ERP Cloud hosting Solutions</title>

                {/* Meta Tags */}
                <meta name="title" content="Sage 500 ERP Hosting Provider | Sage 500 ERP Cloud hosting Solutions" />
                <meta name="focus_keyword" content="Sage 500 ERP Hosting" />
                <meta name="description" content="Concisenext provides robust Sage 500 ERP Cloud Hosting solutions, ensuring secure, flexible access and efficient performance for your business needs, anytime and anywhere" />
                <meta name="keywords" content="concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management" />
                <meta name="robots" content="index, follow" />

                {/* Canonical Link */}
                <link rel="canonical" href={window.location.href} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="ConciseNext: Your Cloud Application Hosting Solution" />
                <meta property="og:description" content="Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs." />
                <meta property="og:image" content="https://concisenext.com/public_image/webp/logocolor.481479ea418411f3e450.webp" />
                <meta property="og:url" content={window.location.href} />
            </Helmet>

            <SageHero />
            <SageIntroSection />
            <SageBenefits />
            <SageHostingWorks />
            <WhyChooseConciseNext />
            <GetStartedSection />
            <Sage50HostingPlans />
            <ContactUs />


        </div>
    )

}