import React, { useEffect, useRef } from 'react';
import { ShieldCheckIcon, GlobeAltIcon, ClockIcon, ChartBarIcon, SupportIcon, CloudIcon, DocumentTextIcon } from '@heroicons/react/solid';
import { motion, useTransform, useScroll } from 'framer-motion';
import dataSec from "../assets/images/dataSecuirity.webp"
import remote from "../assets/images/remotAccess.webp"
import uptime from "../assets/images/uptime.webp"
import scalable from "../assets/images/scalable.webp"
import support from "../assets/images/Support.webp"
import accessibility from "../assets/images/Accessibility.webp"
import multiUsr from "../assets/images/multiUsr.webp"
import dataBckp from "../assets/images/dataBackup.webp"
import qbHosting from "../assets/images/qb/quickbook.webp"
import qbHeroImg from "../assets/images/qbHeroImg.webp"
import { FaUser, FaUsers, FaHdd, FaMemory, FaAppStore, UsersIcon, FaCheckCircle, FaClock, FaHeadset } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

const features = [
  {
    name: 'Enhanced Data Security',
    icon: ShieldCheckIcon,
    description: 'Safeguard your financial data with our advanced security protocols, including encryption, multi-factor authentication, and regular backups. ConciseNext ensures your information is protected against unauthorized access and potential threats..',
    image: dataSec,
  },
  {
    name: 'Seamless Remote Access',
    icon: GlobeAltIcon,
    description: 'Work from anywhere with our cloud-based QuickBooks cloud hosting. Whether you’re at the office, home, or on the go, ConciseNext makes it easy to stay connected to your accounting data, enhancing your productivity and flexibility.',
    image: remote,
  },
  {
    name: 'Unmatched Uptime and Reliability',
    icon: ClockIcon,
    description: 'Experience uninterrupted service with our 99.9% uptime guarantee. ConciseNext’s state-of-the-art data centers and robust infrastructure ensure your QuickBooks software is always available when you need it.',
    image: uptime,
  },
  {
    name: 'Scalable Solutions for Growing Businesses',
    icon: ChartBarIcon,
    description: 'As your business grows, so do your hosting needs. ConciseNext offers scalable hosting plans that can be customized to match your evolving requirements, providing you with the flexibility to expand without the hassle..',
    image: scalable,
  },
  // {
  //   name: 'Expert Support Whenever You Need It',
  //   icon: SupportIcon,
  //   description: 'Our dedicated support team is available around the clock to assist you with any issues or questions. ConciseNext’s QuickBooks hosting specialists are just a phone call or email away, ensuring you get the help you need when you need it.',
  //   image: support,
  // },
  // {
  //   name: 'Comprehensive Compliance and Reporting',
  //   icon: SupportIcon,
  //   description: 'Stay compliant with industry regulations and streamline your reporting processes with our comprehensive compliance tools. ConciseNext provides the resources and support necessary to meet all regulatory requirements efficiently.',
  //   image: support,
  // },
];

const testimonials = [
  {
    quote: "ConciseNext transformed how we handle accounting. Their QuickBooks hosting is reliable, and their support is fantastic.",
    name: "John Doe, CPA",
  },
  {
    quote: "Switching to ConciseNext has saved us time and increased our productivity. We couldn't be happier with the service.",
    name: "Jane Smith, Small Business Owner",
  },
];



const HostingSection = () => {
  return (
    <div className="bg-gray-100 py-20 px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">

        {/* Header Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-extrabold text-gray-800 mb-4">QuickBooks Cloud Hosting  </h1>
          <p className="text-lg text-gray-600">Secure, Reliable, and Scalable Cloud Hosting Solutions</p>
        </div>

        {/* Content Section */}
        <div className="grid md:grid-cols-2 gap-16 items-center">

          {/* Text Content */}
          <div className="space-y-6">
            <p className="text-xl text-gray-700 leading-relaxed">
              At ConciseNext, we specialize in delivering top-notch QuickBooks Cloud Hosting solutions tailored to meet the needs of accounting professionals and small to medium-sized businesses.
              Our secure, reliable, and scalable cloud hosting services allow you to access your QuickBooks software and data from anywhere, at any time, with complete peace of mind.
            </p>
            <div className="flex space-x-4">
              <div className="flex items-center space-x-2">
                <ShieldCheckIcon className="h-8 w-8 text-blue-600" />
                <span className="font-semibold text-gray-800">Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-8 w-8 text-green-600" />
                <span className="font-semibold text-gray-800">Reliable</span>
              </div>
              <div className="flex items-center space-x-2">
                <CloudIcon className="h-8 w-8 text-purple-600" />
                <span className="font-semibold text-gray-800">Scalable</span>
              </div>
            </div>
          </div>

          {/* Image */}
          <div className="relative">
            <img
              src={qbHosting}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-80"
            />
          </div>
        </div>
      </div>
    </div>
  );
};



const HostingSectionCloud= () => {
  return (
    <div className="bg-gray-100 py-20 px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">

        {/* Header Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-extrabold text-gray-800 mb-4">Why host Quickbooks on the cloud? </h1>
          {/* <p className="text-lg text-gray-600">Secure, Reliable, and Scalable Cloud Hosting Solutions</p> */}
        </div>

        {/* Content Section */}
        <div className="grid md:grid-cols-2 gap-16 items-center">

           {/* Image */}
          <div className="relative">
            <img
              src={dataSec}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-80"
            />
          </div>
         
          {/* Text Content */}
          <div className="space-y-6">
            <p className="text-xl text-gray-700 leading-relaxed">
            By hosting QuickBooks on a cloud platform, businesses can access the accounting software anytime, anywhere, ensuring seamless operations whether in the office,
             at home, or on the go. This flexibility boosts productivity, collaboration, and efficiency, making it a vital tool for modern businesses. At ConciseNext, 
             we provide premium QuickBooks hosting solutions tailored for businesses of all 
            sizes. Our platform prioritizes reliability, security, and performance, ensuring your financial data remains accessible and protected at all times.
            </p>
            {/* <div className="flex space-x-4">
              <div className="flex items-center space-x-2">
                <ShieldCheckIcon className="h-8 w-8 text-blue-600" />
                <span className="font-semibold text-gray-800">Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-8 w-8 text-green-600" />
                <span className="font-semibold text-gray-800">Reliable</span>
              </div>
              <div className="flex items-center space-x-2">
                <CloudIcon className="h-8 w-8 text-purple-600" />
                <span className="font-semibold text-gray-800">Scalable</span>
              </div>
            </div> */}
          </div>

        
        </div>
      </div>
    </div>
  );
};


const QuickBooksProHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          QuickBooks Hosting Pricing & Plans
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At <span className="font-bold">ConciseNext</span>, we understand the importance of reliable and secure hosting for your QuickBooks software. That’s why we offer flexible hosting plans designed to meet the unique needs of businesses of all sizes. Whether you're an individual user or part of a growing team, our plans provide the performance, security, and scalability you need to keep your accounting operations running smoothly.
        </motion.p>
      </div>
    </section>
  );
};

const StandardHostingPlan = () => {
  const standardPlans = [
    {
      title: '1-User Plan',
      price: '$33',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-purple-100',
    },
    {
      title: 'Per Additional User',
      price: '$30',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-pink-100',
    }
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">
          {standardPlans.map(plan => (
            <motion.div
              key={plan.title}
              className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div className="">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
                <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
                <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
              </div>
              <div className="flex items-center justify-start text-gray-600">
                <FaHdd className="text-blue-600 mr-2" />
                <span>{plan.storage}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};




const DedicatedHostingPlans = () => {
  const plans = [
    {
      name: 'Standard',
      app1: "Excel",
      app2: "Word",
      app3: "Adobe Reader",
      comp: "MAC Compatible",
      uptime: "99.9% UpTime",
      support: "24x7 Free support",
      price: '$33',
      users: 1,
      additional: '$33',
      storage: '5 GB (5GB/user)',
      apps: 1,
      color: 'bg-blue-200',
    },
    {
      name: 'Silver',
      app1: "Excel",
      app2: "Word",
      app3: "No Setup Fee",
      comp: "MAC Compatible",
      uptime: "99.9% UpTime",
      support: "24x7 Free support",
      price: '$99',
      users: 2,
      additional: '$34',
      storage: '10 GB (5GB/user)',
      apps: 2,
      color: 'bg-gray-300',
    },
    {
      name: 'Gold',
      app1: "Excel",
      app2: "Word",
      app3: "No Setup Fee",
      comp: "MAC Compatible",
      uptime: "99.9% UpTime",
      support: "24x7 Free support",
      price: '$199',
      users: 5,
      additional: '$29',
      storage: '30 GB (6GB/user)',
      apps: 2,
      color: 'bg-yellow-200',
    }
  ];

  return (
    <section className="py-16 bg-img">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-s mb-12  text-center text-gray-800">Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">

          {plans.map(plan => (
            <motion.div
              key={plan.name}
              className={`${plan.color}  p-6 rounded-3xl shadow-lg card-border w-full sm:w-[48%] md:w-[30%] lg:w-[25%] h-[490px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <Link to="/price">
                <div>
                  <h3 className="text-2xl font-semibold mb-4 text-start text-gray-800">{plan.name}</h3>
                  <div className="text-start mb-4">
                    <span className="text-3xl font-bold text-gray-800">{plan.price}</span>
                    <span className="text-lg text-gray-600">/month</span>
                  </div>
                </div>
                <ul className="space-y-4 text-gray-600">
                  <li className="flex items-center justify-start">
                    <FaUsers className="text-blue-600 mr-2" />
                    No. of Users: {plan.users}
                  </li>
                  <li className="flex items-center justify-start">
                    <FaUser className="text-green-600 mr-2" />
                    Additional User: {plan.additional}
                  </li>
                  <li className="flex items-center justify-start">
                    <FaHdd className="text-yellow-600 mr-2" />
                    Storage: {plan.storage}
                  </li>
                  <li className="flex items-center justify-start">
                    <FaAppStore className="text-purple-600 mr-2" />
                    Included Apps: {plan.apps}
                  </li>
                  <li className="flex items-center justify-start">
                    <FaCheckCircle className="text-green-600 mr-2" />
                    Apps: {plan.app1}, {plan.app2}, {plan.app3}
                  </li>
                  <li className="flex items-center justify-start">
                    <FaCheckCircle className="text-green-600 mr-2" />
                    Compatibility: {plan.comp}
                  </li>
                  <li className="flex items-center justify-start">
                    <FaClock className="text-cyan-600 mr-2" />
                    Uptime: {plan.uptime}
                  </li>
                  <li className="flex items-center justify-start">
                    <FaHeadset className="text-red-600 mr-2" />
                    Support: {plan.support}
                  </li>
                </ul>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};





const DifferenceSection = () => {
  return (
    <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800 via-blue-500 to-purple-300 opacity-1 blur-lg"></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">Discover the ConciseNext Difference</h2>
        <p className="text-lg leading-relaxed mb-8">
          Transitioning to cloud-hosted QuickBooks with ConciseNext means more than just software hosting—it’s about partnering with a provider that understands your business needs and is committed to your success.
          Join the growing number of businesses that trust ConciseNext for their QuickBooks hosting and experience a new level of efficiency and reliability.
        </p>
        <button className="bg-white text-button font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:scale-105">
          Start Your 7 Day Free Trial
        </button>
      </div>


    </div>
  );
};

function PricingCard({ title, price, features }) {
  return (
    <div className="max-w-sm rounded-lg shadow-md overflow-hidden">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 mb-4">{price}</p>
        <ul>
          {features.map((feature, index) => (
            <li key={index} className="text-gray-700 mb-2">{feature}</li>
          ))}
        </ul>
      </div>
      <div className="px-6 py-4 border-t border-gray-200">
        <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Start Now
        </button>
      </div>
    </div>
  );
}


const Hero = () => {
  const { scrollY } = useScroll();
  const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
  const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
  const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
  const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <div className="relative h-[500px] md:h-[600px]  bg-[url('https://concisenext.com/public_image/webp/network-2402637_1280.webp')] bg-cover bg-center bg-no-repeat overflow-hidden">
      {/* Background Circles Effect */}
      <div className="absolute flex items-center justify-center">
        <motion.div
          className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
          style={{ scale: scale1 }}
        />
        <motion.div
          className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
          style={{ scale: scale2 }}
        />
        <motion.div
          className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
          style={{ scale: scale3 }}
        />
      </div>

      {/* Hero Content */}
      <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
        <motion.h1
          className="text-3xl sm:text-4xl md:text-3xl font-bold mb-2 md:mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          QuickBooks Hosting 
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
          Secure, Reliable, and Scalable Cloud Solutions for Accounting Professionals
        </motion.p>
        <motion.a
          href="/free-trial"
          className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
        >
          Start Your 7 Day Free Trial
        </motion.a>
      </div>



    </div>
  );
};



const QuickboksHosting = () => {
  const featureRefs = useRef([]);




  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const element = entry.target;
          if (entry.isIntersecting) {
            element.classList.add('animate-slideIn');
            element.classList.remove('opacity-0');
          } else {
            element.classList.remove('animate-slideIn');
            element.classList.add('opacity-0');
          }
        });
      },
      {
        threshold: 0.2,
      }
    );

    featureRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      featureRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>QuickBooks Cloud Hosting Provider | Desktop Cloud Hosting</title>
        <meta name="title" content="QuickBooks Cloud Hosting Provider | Desktop Cloud Hosting" />
        <meta name="focus_keyword" content="QuickBook Hosting" />
        <meta name="description" content="ConciseNext provides secure QuickBooks hosting on a cloud server, enabling you to access QuickBooks Desktop anytime, anywhere, from any device. Experience seamless cloud hosting for your QuickBooks needs." />
        <meta name="keywords" content="concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />

        <meta property="og:title" content="ConciseNext: Your Cloud Application Hosting Solution" />
        <meta property="og:description" content="Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs." />
        <meta property="og:image" content="https://concisenext.com/public_image/webp/logocolor.481479ea418411f3e450.webp" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="scroll-smooth">
        <Hero />
        <HostingSection />
        <HostingSectionCloud />

        {/* Why Choose ConciseNext Section */}
        <div className="bg-gray-100 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12 animate-fadeIn">Why Choose ConciseNext as QuickBooks Cloud Hosting Provider?</h2>
            <div className="grid md:grid-cols-2 gap-16">
              {features.map((feature, index) => (
                <div
                  key={index}
                  ref={(el) => (featureRefs.current[index] = el)}
                  className="flex flex-col md:flex-row items-start bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-500"
                >
                  <div className="p-4 rounded-full bg-button text-white mb-4 md:mb-0 md:mr-6">
                    <feature.icon className="h-8 w-8" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{feature.name}</h3>
                    <p className="mb-4 card-info">{feature.description}</p>
                    <img
                      src={feature.image}
                      alt={feature.name}
                      className="rounded-lg shadow-lg hover:shadow-xl transition duration-500 opacity-1"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <DifferenceSection />














      
      {/* Additional Content Section */}
<div className="bg-white py-20">
  <div className="container mx-auto px-6">
    <div className="container mx-auto p-4">
    <h2 className="text-3xl font-bold text-center mb-12 animate-fadeIn">
      Comparison Between Local-Hosting vs QuickBooks Cloud Hosting
    </h2>
   
      <p className="mb-6 text-center">
        Choosing between local-hosted and cloud-hosted QuickBooks depends on your business requirements.
        Local hosting may work for businesses with existing IT infrastructure and minimal remote access needs.
        However, for those prioritizing scalability, security, high performance, and remote accessibility,
        our cloud-hosted QuickBooks offers a more efficient solution.
      </p>

      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-left border border-gray-300">Features</th>
            <th className="px-4 py-2 text-left border border-gray-300">Local-Hosted QuickBooks</th>
            <th className="px-4 py-2 text-left border border-gray-300">Cloud-Hosted QuickBooks with ConciseNext</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2 border border-gray-300">Accessibility</td>
            <td className="px-4 py-2 border border-gray-300">Limited to specific devices, requiring on-site access.</td>
            <td className="px-4 py-2 border border-gray-300">Access from anywhere, anytime, on any internet-enabled device.</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="px-4 py-2 border border-gray-300">Data Security</td>
            <td className="px-4 py-2 border border-gray-300">Risk of data loss due to hardware failure, theft, or disasters.</td>
            <td className="px-4 py-2 border border-gray-300">Automatic backups, advanced security, and data encryption.</td>
          </tr>
          <tr>
            <td className="px-4 py-2 border border-gray-300">Performance</td>
            <td className="px-4 py-2 border border-gray-300">Dependent on local hardware, may slow down over time.</td>
            <td className="px-4 py-2 border border-gray-300">Optimized performance with scalable cloud resources.</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="px-4 py-2 border border-gray-300">Collaboration</td>
            <td className="px-4 py-2 border border-gray-300">Difficult for multiple users to access simultaneously.</td>
            <td className="px-4 py-2 border border-gray-300">Seamless multi-user access with real-time collaboration.</td>
          </tr>
          <tr>
            <td className="px-4 py-2 border border-gray-300">Software Updates</td>
            <td className="px-4 py-2 border border-gray-300">Compatibility issues and manual updates required.</td>
            <td className="px-4 py-2 border border-gray-300">Always up to date with the latest QuickBooks version.</td>
          </tr>
          <tr className="bg-gray-50">
            <td className="px-4 py-2 border border-gray-300">Scalability</td>
            <td className="px-4 py-2 border border-gray-300">Limited by local infrastructure capacity.</td>
            <td className="px-4 py-2 border border-gray-300">Easily scales to meet business growth.</td>
          </tr>
        </tbody>
      </table>

      <div className="mt-6 text-center">
        <p className="font-semibold">Choose ConciseNext for a seamless, secure, and scalable QuickBooks hosting experience!</p>
      </div>
    </div>
    <div className="grid md:grid-cols-3 gap-8">
      <div ref={(el) => (featureRefs.current[0] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
        <h3 className="text-2xl font-semibold mb-4 text-[#111827]">Complete Accessibility</h3>
        <p className="mb-4 card-info">
          Access your QuickBooks software from any device, anytime, anywhere. Whether you’re on your laptop, tablet, or smartphone, ConciseNext ensures you’re always connected to your data.
        </p>
        <img
          src={accessibility}
          alt="Accessibility"
          className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
          style={{ maxWidth: "100%", height: "300px" }}
        />
      </div>
      <div ref={(el) => (featureRefs.current[1] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Data Backup & Recovery</h3>
        <p className="mb-4 card-info">
          We provide automated data backups and quick recovery options to ensure your financial data is always secure, even in the event of unexpected issues.
        </p>
        <img
          src={dataBckp}
          alt="Backup and Recovery"
          className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
          style={{ maxWidth: "100%", height: "300px" }}
        />
      </div>
      <div ref={(el) => (featureRefs.current[2] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Multi-User Collaboration</h3>
        <p className="mb-4 card-info">
          Collaborate with your team in real-time. With QuickBooks hosting by ConciseNext, multiple users can access and work on the same files simultaneously.
        </p>
        <img
          src={multiUsr}
          alt="Collaboration"
          className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
          style={{ maxWidth: "100%", height: "300px" }}
        />
      </div>
    </div>
  </div>
</div>

{/* Background Effect */}
<div className="relative py-20 px-6">
  <div className="absolute inset-0 bg-gradient-to-r from-gray-800 via-gray-500 to-gray-300 opacity-.2 blur-lg"></div>
  <div className="relative container mx-auto max-w-5xl text-center text-white">
    <h2 className="text-4xl font-extrabold mb-6">QuickBooks Desktop Cloud Hosting Solutions</h2>
    <p className="text-lg leading-relaxed mb-8">
      Leverage the power of the cloud with QuickBooks Desktop hosting to experience unmatched security, flexibility, and reliability.
    </p>
  </div>
</div>

{/* Additional Hosting Features Section */}
<div className="bg-white py-20">
  <div className="container mx-auto px-6">
    <div className="grid md:grid-cols-3 gap-8">
      <div ref={(el) => (featureRefs.current[3] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
        <h3 className="text-2xl font-semibold mb-4 text-[#111827]">Custom Cloud Workspace</h3>
        <p className="mb-4 card-info">
          Enjoy a fully personalized accounting environment with cloud-hosted QuickBooks. Seamlessly integrate all your essential business applications and add-ons into a single, secure cloud platform.
        </p>
        <img
          src={accessibility}
          alt="Custom Cloud Workspace"
          className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
          style={{ maxWidth: "100%", height: "300px" }}
        />
      </div>
      <div ref={(el) => (featureRefs.current[4] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">High-End Security</h3>
        <p className="mb-4 card-info">
          Protect your financial data with advanced security measures, including IDS/IPS threat detection, multi-factor authentication, layered firewalls, and Endpoint Detection & Response (EDR) for real-time protection.
        </p>
        <img
          src={dataBckp}
          alt="High-End Security"
          className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
          style={{ maxWidth: "100%", height: "300px" }}
        />
      </div>
      <div ref={(el) => (featureRefs.current[5] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Multi-User Collaboration</h3>
        <p className="mb-4 card-info">
          Enable seamless teamwork by allowing accountants, finance teams, and clients to access QuickBooks in real time. Work together more efficiently with instant data sharing and better decision-making.
        </p>
        <img
          src={dataBckp}
          alt="High-End Security"
          className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
          style={{ maxWidth: "100%", height: "300px" }}
        />
      </div>
  
    </div>
  </div>
</div>

  

  <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-gray-800 via-gray-500 to-gray-300 opacity-.2 blur-lg"></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">
        Steps to Follow for QuickBooks Desktop Cloud Hosting
        </h2>
        <p className="text-lg leading-relaxed mb-8">
        Hosting your QuickBooks Desktop on the cloud with ConciseNext is 
        a simple and efficient process. Follow these three easy steps to get started:
        </p>
        
      </div>
    </div>


  <div className="bg-white py-20 ">
          <div className="container mx-auto px-6">
      
            <div className="grid md:grid-cols-3 gap-8">
              <div ref={(el) => (featureRefs.current[6] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
                <h3 className="text-2xl font-semibold mb-4 text-[#111827]">Step 1: Pick the Right Plan</h3>
                <p className="mb-4 card-info">Consult with our experts to select a QuickBooks hosting plan that best suits your business needs.
                 We offer flexible plans tailored for different industries and business sizes.
               </p>
                <img
                  src={accessibility}
                  alt="Accessibility"
                  className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              </div>
              <div ref={(el) => (featureRefs.current[7] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
                <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Step 2: Share QuickBooks Details</h3>
                <p className="mb-4 card-info">
                Provide your QuickBooks license details to initiate cloud hosting services.
                 If you don’t have a license, you can purchase one through us for a seamless setup.
              </p>
                <img
                  src={dataBckp}
                  alt="Backup and Recovery"
                  className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              </div>
              <div ref={(el) => (featureRefs.current[8] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
                <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Step 3: Cloud Setup</h3>
                <p className="mb-4 card-info">Based on your user requirements, RAM, and storage needs, we configure your QuickBooks cloud environment.
                 Our team ensures a smooth transition, completing the setup within hours.</p>
                <img
                  height
                  src={multiUsr}
                  alt="Collaboration"
                  className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              </div>
              {/* <div ref={(el) => (featureRefs.current[7] = el)} className="bg-gray-100 p-8 rounded-lg shadow-lg opacity-0 transform translate-y-10">
                <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Unmatched Performance</h3>
                <p className="mb-4 card-info">Get lightning-fast performance with US-based data centers, 
                SSD-powered HPC servers, and a 99.99% uptime guarantee for smooth and reliable operations.</p>
                <img
                  height
                  src={multiUsr}
                  alt="Collaboration"
                  className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              </div> */}
            </div>
          </div>
        </div>






        <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800 via-blue-500 to-purple-600 opacity-1 blur-lg"></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">
        Versions of quick books we host 
        </h2>
   <ul>
    <li>QuickBook Pro Hosting </li>
    <li>QuickBooks Premier Hosting</li>
    <li>QuickBooks Enterprise Hosting</li>
    <li>QuickBooks POS Hosting</li>
    <li>QuickBooks Accountant </li>
   </ul>
      </div>  
    </div>


    <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-gray-800 via-gray-500 to-gray-600 opacity-.2 blur-lg"></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">
        Why Choose ConciseNext for QuickBooks Hosting?
        </h2>
   <ul>
    <li>✅ Remote Access – Work from anywhere, anytime</li>
    <li>✅ Enhanced Security – Multi-layered protection & automatic backups</li>
    <li>✅ 24/7 Support – Expert assistance whenever you need it</li>
    <li>✅ Seamless Performance – Fast, reliable, and scalable hosting</li>
    <li>Contact us today to find the perfect QuickBooks hosting plan for your business! </li>
   </ul>
      </div>  
    </div>

        
       
   

        <QuickBooksProHostingIntro />
        {/* <StandardHostingPlan /> */}
        <DedicatedHostingPlans />

        <div className="max-w-3xl mx-auto p-6 bg-gradient-to-r from-blue-100 to-blue-50">
  <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">FAQs</h1>

  <div className="space-y-8">
    <div className="bg-white p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
      <h2 className="text-2xl font-semibold text-indigo-700">1. How can I reduce expenses with QuickBooks hosting?</h2>
      <p className="text-gray-600 mt-2">
        QuickBooks hosting eliminates the need for costly hardware, IT maintenance, and software upgrades. You only pay for what you need, reducing overall operational costs.
      </p>
    </div>

    <div className="bg-white p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
      <h2 className="text-2xl font-semibold text-indigo-700">2. Who is responsible for transferring my data to and from the server?</h2>
      <p className="text-gray-600 mt-2">
        Our team handles secure data migration to and from the cloud, ensuring a smooth transition without data loss or downtime.
      </p>
    </div>

    <div className="bg-white p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
      <h2 className="text-2xl font-semibold text-indigo-700">3. What are my options if I don’t have a QuickBooks license?</h2>
      <p className="text-gray-600 mt-2">
        You can either purchase a QuickBooks license directly from Intuit or opt for a hosted license through us for added convenience.
      </p>
    </div>

    <div className="bg-white p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
      <h2 className="text-2xl font-semibold text-indigo-700">4. What advantages does QuickBooks hosting offer for my business?</h2>
      <p className="text-gray-600 mt-2">
        Cloud-hosted QuickBooks provides remote access, enhanced security, automated backups, multi-user collaboration, and seamless performance, boosting efficiency and flexibility.
      </p>
    </div>

    <div className="bg-white p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
      <h2 className="text-2xl font-semibold text-indigo-700">5. Can I keep a local backup of my data files on my desktop?</h2>
      <p className="text-gray-600 mt-2">
        Yes, you can download and store local backups of your QuickBooks data for additional security and accessibility.
      </p>
    </div>

    <div className="bg-white p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
      <h2 className="text-2xl font-semibold text-indigo-700">6. If I switch from QuickBooks Desktop to the cloud, will I lose any features, or will I retain full desktop functionality?</h2>
      <p className="text-gray-600 mt-2">
        You retain full QuickBooks Desktop functionality with added benefits like remote access, better security, and enhanced performance.
      </p>
    </div>

    <div className="bg-white p-6 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
      <h2 className="text-2xl font-semibold text-indigo-700">7. What is the process for installing QuickBooks on the server?</h2>
      <p className="text-gray-600 mt-2">
        We handle the entire setup, including QuickBooks installation, configuration, and optimization, so you can start using it seamlessly without technical hassle.
      </p>
    </div>
  </div>
</div>

      </div>
    </>

  );
};

export default QuickboksHosting;