import React, { useEffect, useRef } from 'react';
import { ShieldCheckIcon, GlobeAltIcon, ClockIcon, ChartBarIcon, SupportIcon, CloudIcon } from '@heroicons/react/solid';
import { FaHandsHelping, FaCloudUploadAlt, FaTools, FaShieldAlt } from 'react-icons/fa'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import dataSec from "../assets/images/datasecurity.webp";
import multiUsr from "../assets/images/multiUsr.webp"
import { Helmet } from 'react-helmet';

import accdata from "../assets/images/accesdata.webp"
import dataBckp from "../assets/images/dataBackup.webp"
import relper from "../assets/images/relper.webp"
import techsuport from "../assets/images/techsup.webp"
import teamwrk from "../assets/images/teamwrk.webp"
import enterprise2 from "../assets/images/enterprise2.webp"
import etertprisefirst from "../assets/images/enterprisefirst.webp"
import enterprisesec from "../assets/images/enterprisesecu.webp"
import enterpriserem from "../assets/images/enterpriseremote.webp"
import enterpriseavl from "../assets/images/enterpriserelibilities.webp"
import posremote from "../assets/images/posremote.webp"
import posSecu from "../assets/images/possecurity.webp"
import posrel from "../assets/images/posrel.webp"
import posinteg from "../assets/images/postintegra.webp"
import poscost from "../assets/images/poscost.webp"
import addon from "../assets/images/qb/add-ons.webp"
import pos1 from "../assets/images/poshosting.webp"
import possup from "../assets/images/possuport.webp"
import adons1 from "../assets/images/addons1.webp"

import { FaUser, FaUsers, FaHdd, FaMemory, FaAppStore } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const features = [
  {
    id: 1,
    name: 'Centralized Access to All Your Tools',
    icon: ShieldCheckIcon,
    description: 'With ConciseNext hosting, you can access QuickBooks and all your essential add-ons from one centralized cloud platform. Whether you’re using tools for inventory management, payroll processing, or CRM, our hosting ensures that everything is seamlessly connected and accessible from anywhere, at any time.',
    image: posremote,
  },
  {
    id: 2,
    name: 'Enhanced Security for Peace of Mind',
    icon: GlobeAltIcon,
    description: 'Experience consistent and reliable performance with ConciseNext QuickBooks add-ons hosting. Our 99.9% uptime guarantee ensures that your integrated tools are always available when you need them, minimizing disruptions and maximizing productivity.',
    image: posSecu,
  },
  {
    id: 3,
    name: 'Scalable Hosting Solutions',
    icon: ClockIcon,
    description: 'As your business grows and your needs evolve, ConciseNext hosting solutions can scale with you. Whether you need more resources, additional add-ons, or expanded storage, our flexible hosting plans are designed to accommodate your changing requirements.',
    image: posrel,
  },
  {
    id: 4,
    name: 'Reliable Performance with High Uptime',
    icon: ChartBarIcon,
    description: 'Experience consistent and reliable performance with ConciseNext QuickBooks add-ons hosting. Our 99.9% uptime guarantee ensures that your integrated tools are always available when you need them, minimizing disruptions and maximizing productivity.',
    image: posinteg,
  },
  {
    id: 11,
    name: 'Streamlined Integration and Collaboration',
    icon: SupportIcon,
    description: 'ConciseNext hosting enables smooth integration between QuickBooks and its add-ons, ensuring that your financial data flows seamlessly across all tools. Multiple users can collaborate in real-time, making your accounting processes more efficient and coordinated.',
    image: teamwrk,
  },
  {
    id: 16,
    name: 'Cost-Efficient and Hassle-Free',
    icon: CloudIcon,
    description: 'Reduce your IT overhead by moving your QuickBooks add-ons to the cloud. ConciseNext hosting eliminates the need for on-premise servers and technical maintenance, providing a cost-effective solution that lets you focus on growing your business.',
    image: poscost,
  },
];





const BenefitsSection = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white py-10">
      <div className="container mx-auto px-6">
        <h2 className={`text-3xl font-bold text-center mb-6 ${inView1 ? 'animate-fadeIn' : ''}`}>
          Key Benefits of QuickBooks Hosting with ConciseNext
        </h2>
        <div className="bg-white py-20">
  <div className="container mx-auto px-6">
   
    <ul className="grid md:grid-cols-2 gap-8 text-lg">
      <li className="bg-gray-100 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-2">1. Seamless Integration</h3>
        <p>
          Host multiple QuickBooks add-ons in a unified cloud environment, ensuring real-time data synchronization and smooth functionality across applications.
        </p>
      </li>
      <li className="bg-gray-100 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-2">2. Enhanced Performance & Accessibility</h3>
        <p>
          Run QuickBooks and its add-ons from anywhere, anytime, on any device with stable, high-speed cloud access.
        </p>
      </li>
      <li className="bg-gray-100 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-2">3. Secure Data Storage & Backup</h3>
        <p>
          Benefit from advanced security protocols, including encryption, multi-factor authentication, and automated daily backups to safeguard critical financial data.
        </p>
      </li>
      <li className="bg-gray-100 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-2">4. Scalability for Business Growth</h3>
        <p>
          Easily scale your hosted environment to accommodate new add-ons and increased data without the hassle of local infrastructure upgrades.
        </p>
      </li>
      <li className="bg-gray-100 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-2">5. Multi-User Collaboration</h3>
        <p>
          Enable multiple users to work simultaneously on QuickBooks and its add-ons with controlled role-based access for efficient team collaboration.
        </p>
      </li>
      <li className="bg-gray-100 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-2">6. Reliable IT Support & Maintenance</h3>
        <p>
          ConciseNext provides 24/7 technical support to resolve issues quickly, ensuring smooth operations without downtime or disruptions.
        </p>
      </li>
      <li className="bg-gray-100 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-2">7. Cost-Effective & Time-Saving</h3>
        <p>
          Reduce IT expenses by eliminating the need for local servers.
        </p>
      </li>
    </ul>
  </div>
</div>
        <div className="grid md:grid-cols-3 gap-8">
          <div
            ref={ref1}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView1 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">24/7 Expert Support</h3>
            <p className="mb-4 card-info">
              Our team of QuickBooks hosting experts is available around the clock to assist with any issues or questions. ConciseNext is committed to providing prompt, reliable support, ensuring that your QuickBooks add-ons work seamlessly and efficiently at all times.
            </p>
            <img
              src={possup}
              alt="Accessibility"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref2}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView2 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Cost-Effective Hosting Solutions</h3>
            <p className="mb-4 card-info">
              Reduce your IT expenses with ConciseNext’s affordable QuickBooks Add-ons hosting. By hosting your QuickBooks Add-ons in the cloud, you eliminate the need for costly in-house servers and IT maintenance, allowing you to focus on what matters most—growing your business.
            </p>
            <img
              src={poscost}
              alt="Backup and Recovery"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref3}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView3 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Multi-User Collaboration</h3>
            <p className="mb-4 card-info">
              Collaborate with your team in real-time. With QuickBooks hosting by ConciseNext, multiple users can access and work on the same files simultaneously.
            </p>
            <img
              src={multiUsr}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HostingSection = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 md:py-20 md:px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">
        {/* Header Section */}
        <div className="text-center mb-6 md:mb-16">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-3 md:mb-4">
          QuickBooks Add-Ons Hosting Services 
          </h1>
          <p className="text-base md:text-lg text-gray-600">
            Elevate Your Accounting with Tailored Cloud Solutions
          </p>
        </div>
        {/* Content Section */}
        <div className="grid gap-10 md:gap-16 items-center grid-cols-1 md:grid-cols-2">
          {/* Text Content */}
          <div className="space-y-4 md:space-y-6">
            <p className="text-base md:text-xl text-gray-700 leading-relaxed">
              ConciseNext offers specialized hosting services for QuickBooks add-ons, enabling businesses to extend the functionality of their QuickBooks software. Our cloud hosting solutions provide seamless integration, enhanced accessibility, and robust security, ensuring your QuickBooks add-ons work efficiently alongside your core accounting system.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-2">
                <ShieldCheckIcon className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <span className="font-semibold text-gray-800">Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-6 w-6 md:h-8 md:w-8 text-green-600" />
                <span className="font-semibold text-gray-800">Reliable</span>
              </div>
              <div className="flex items-center space-x-2">
                <CloudIcon className="h-6 w-6 md:h-8 md:w-8 text-purple-600" />
                <span className="font-semibold text-gray-800">Scalable</span>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="relative">
            <img
              src={addon}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-60 md:h-80"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuickBooksAddOnsHost = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          What is QuickBooks Add-Ons Hosting ? 
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
         QuickBooks Add-Ons Hosting allows businesses to integrate and run third-party applications alongside QuickBooks in a secure, cloud-based environment. These add-ons enhance functionality by supporting advanced reporting, payroll processing, inventory management, CRM, and more. With cloud hosting, users can access their QuickBooks and integrated tools 
         from anywhere, ensuring seamless data synchronization, improved efficiency, and better collaboration. ConciseNext provides a reliable, scalable hosting solution tailored to your business needs.
        </motion.p>
      </div>
    </section>
  );
};


const QuickBooksProHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          QuickBooks Add-ons Hosting Pricing & Plans
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At <span className="font-bold">ConciseNext</span>, we offer flexible hosting plans designed to meet the unique needs of businesses of all sizes. Whether you're an individual user or part of a growing team, our plans provide the performance, security, and scalability you need to keep your accounting operations running smoothly.
        </motion.p>
      </div>
    </section>
  );
};

const StandardHostingPlan = () => {
  const standardPlans = [
    {
      title: '1-User Plan',
      price: '$33',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-purple-100',
    },
    {
      title: 'Per Additional User',
      price: '$30',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-pink-100',
    }
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">
          {standardPlans.map(plan => (
            <motion.div
              key={plan.title}
              className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
                <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
                <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
              </div>
              <div className="flex items-center justify-center text-gray-600">
                <FaHdd className="text-blue-600 mr-2" />
                <span>{plan.storage}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};



const industries = [
  {
    title: "Retail and E-commerce",
    description:
      "Integrate QuickBooks with your POS and inventory management tools for streamlined operations.",
    imageUrl:
      "https://concisenext.com/public_image/webp/e-commerce-8837703_1280.webp",
  },
  {
    title: "Construction and Real Estate",
    description:
      "Host project management add-ons to track job costs and manage budgets efficiently.",
    imageUrl:
      "https://concisenext.com/public_image/webp/photo-1669003154400-053af9a35eba.webp",
  },
  {
    title: "Healthcare",
    description:
      "Ensure compliance with HIPAA while managing payroll and billing add-ons in a secure environment.",
    imageUrl:
      "https://concisenext.com/public_image/webp/istockphoto-1903423742-2048x2048.webp",
  },
  {
    title: "Manufacturing",
    description:
      "Utilize production and inventory add-ons to keep track of materials, costs, and workflows.",
    imageUrl:
      "https://concisenext.com/public_image/webp/e-commerce-8837703_1280.webp",
  },
  {
    title: "Professional Services",
    description:
      "Integrate CRM and time-tracking tools with QuickBooks to enhance client management and billing.",
    imageUrl:
      "https://concisenext.com/public_image/webp/ai-generated-8846758_960_720.webp",
  },
  {
    title: "Education",
    description:
      "Manage student accounts, track donations, and streamline administrative tasks with QuickBooks tailored for educational institutions.",
    imageUrl:
      "https://concisenext.com/public_image/webp/child-865116_1280.webp"
  },
];


const IndustriesWeServe = () => {
  return (
    <div className="max-w-6xl mx-auto p-6">
      <h2 className="text-4xl font-bold text-center mb-10">
        Industries We Serve
      </h2>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {industries.map((industry, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg overflow-hidden hover:scale-105 transition-transform duration-500"
          >
            <img
              src={industry.imageUrl}
              alt={industry.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">
                {industry.title}
              </h3>
              <p>{industry.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center mt-12 border-1 p-4">
        <h3 className="text-3xl font-bold mb-4">
          Get Started with ConciseNext QuickBooks Add-Ons Hosting Today
        </h3>
        <p className="mb-8">
          Ready to take your QuickBooks capabilities to the next level? Contact ConciseNext today to learn more about our QuickBooks add-ons hosting services and how we can help you optimize your accounting processes in the cloud.
        </p>
        <Link to={"/contact-us"} className="bg-button text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors">
          Contact Us
        </Link>
      </div>
    </div>
  );
};


const HowItWorks = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });

  const fadeInClass = (inView) =>
    inView ? 'opacity-100 translate-y-0 scale-100' : 'opacity-0 translate-y-8 scale-95';

  return (
    <div className="max-w-5xl mx-auto p-5">
      <h2 className="text-4xl font-bold text-center mb-8">
        How ConciseNext’s QuickBooks Add-Ons Hosting Works--
      </h2>

      <div className={`flex items-start mb-8 transition-transform duration-[1000ms] ${fadeInClass(inView1)}`} ref={ref3}>
        <FaHandsHelping className="text-4xl text-blue-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">1. Consultation and Planning</h3>
          <p>
            Our process begins with a consultation where we assess your specific business needs and goals. ConciseNext experts will work with you to determine which QuickBooks add-ons are essential and how they can be optimized in a cloud environment.
          </p>
        </div>
      </div>

      <div className={`flex items-start mb-8 transition-transform duration-[1000ms] ${fadeInClass(inView2)}`} ref={ref2}>
        <FaCloudUploadAlt className="text-4xl text-green-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">2. Seamless Migration</h3>
          <p>
            ConciseNext ensures a smooth transition to the cloud with our seamless migration services. We’ll handle the entire process, including data transfer and system setup, so you experience minimal disruption to your business operations.
          </p>
        </div>
      </div>

      <div className={`flex items-start mb-8 transition-transform duration-[1000ms] ${fadeInClass(inView3)}`} ref={ref3}>
        <FaTools className="text-4xl text-yellow-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">3. Ongoing Optimization</h3>
          <p>
            Once your QuickBooks add-ons are hosted in the cloud, ConciseNext provides ongoing optimization services. We regularly monitor performance, apply updates, and adjust resources as needed to ensure your system runs efficiently.
          </p>
        </div>
      </div>

      <div className={`flex items-start mb-2 transition-transform duration-[1000ms] ${fadeInClass(inView4)}`} ref={ref4}>
        <FaShieldAlt className="text-4xl text-red-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">4. Continuous Support and Maintenance</h3>
          <p>
            Our relationship doesn’t end once you’re up and running. ConciseNext offers continuous support and maintenance, ensuring that any issues are promptly addressed and that your QuickBooks environment remains up to date with the latest features and security patches.
          </p>
        </div>
      </div>
    </div>
  );
};


const AdditionalFeatures = () => {
  // Hook for triggering animations on scroll
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref6, inView6] = useInView({ triggerOnce: true, threshold: 0.1 });

  const fadeInClass = (inView) =>
    inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8';

  return (
    <div className="max-w-5xl mx-auto p-6">
      <h2 className="text-4xl font-bold text-center mb-8">
        Additional Features of ConciseNext’s QuickBooks Add-Ons Hosting
      </h2>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView1)}`} ref={ref1}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">1. Wide Range of Compatible Add-Ons</h3>
        <p className="mb-8">
          ConciseNext supports a wide variety of QuickBooks add-ons, including those for project management,
          e-commerce, sales tax automation, and more. Whatever your business needs, our hosting services
          are designed to ensure that your add-ons function flawlessly in the cloud environment.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView2)}`} ref={ref2}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">2. Customizable Hosting Plans</h3>
        <p className="mb-8">
          We understand that every business is unique. That’s why ConciseNext offers customizable hosting plans
          that allow you to choose the resources and features that best suit your needs. From basic packages
          to enterprise-level solutions, we have a plan that fits your business.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView3)}`} ref={ref3}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">3. Automated Backups and Disaster Recovery</h3>
        <p className="mb-8">
          ConciseNext takes data protection seriously. Our hosting services include automated daily backups
          and comprehensive disaster recovery options, so you can rest easy knowing that your critical data is safe
          and can be quickly restored in the event of an emergency.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView4)}`} ref={ref4}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">4. Global Accessibility</h3>
        <p className="mb-8">
          Our cloud hosting solutions are designed to provide global accessibility. No matter where your team is located,
          they can securely access QuickBooks and all connected add-ons, ensuring your business operations remain smooth
          and efficient across borders.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView5)}`} ref={ref5}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">5. User-Friendly Interface</h3>
        <p className="mb-8">
          ConciseNext hosting platform is designed to be user-friendly, with an intuitive interface that makes managing
          your QuickBooks and add-ons simple and straightforward. Even if you’re not tech-savvy, you’ll find our platform
          easy to navigate.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView6)}`} ref={ref6}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">6. Compliance with Industry Standards</h3>
        <p>
          ConciseNext hosting services comply with key industry standards, including GDPR, HIPAA, and PCI DSS, ensuring that
          your financial data is managed according to the highest regulatory requirements. This compliance helps protect your
          business from legal and financial risks.
        </p>
      </div>
    </div>
  );
};


const DifferenceSection = () => {
  return (
    <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800 via-blue-500 to-purple-300 opacity-1 "></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">Unlock the Full Potential of QuickBooks with ConciseNext’s Add-Ons Hosting</h2>
        <p className="text-lg leading-relaxed mb-8">
          By choosing ConciseNext for your QuickBooks add-ons hosting, you’re enhancing your accounting software with the power and flexibility of the cloud. Our tailored hosting solutions ensure that your QuickBooks environment is fully optimized, helping you to achieve greater efficiency and business success.
        </p>
        <button className="bg-white text-button font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:scale-105">
          Start Your 7 Day Free Trial
        </button>
      </div>
    </div>
  );
};

const SmoothScrollFeature = ({ feature, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  useEffect(() => {
    if (inView && window.innerWidth > 768) {  // Check if screen is larger than 768px
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={window.innerWidth > 768 ? controls : 'visible'}  // Disable animation on small screens
      variants={{
        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.7, ease: 'easeOut' }}
      className="grid md:grid-cols-2 gap-8 xl:gap-20 items-center py-8"
    >
      {/* Image */}
      <div className={`${index % 2 === 0 ? 'order-last md:order-first' : ''}`}>
        <img
          src={feature.image}
          alt={feature.name}
          className="rounded-lg shadow-lg transform hover:scale-105 h-[auto] w-[400px] xl:h-[auto] xl:w-[600px] transition duration-500"
        />
      </div>
      {/* Text */}
      <div>
        <h3 className="text-2xl font-bold text-gray-800 mb-4">{feature.name}</h3>
        <p className="text-lg text-gray-700 leading-relaxed">{feature.description}</p>
      </div>
    </motion.div>
  );
};

const Hero = () => {
  const { scrollY } = useScroll();
  const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
  const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
  const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
  const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <div className="relative h-[500px] md:h-[600px]  bg-[url('https://concisenext.com/public_image/webp/office-581131_1280.webp')] bg-cover bg-center bg-no-repeat overflow-hidden">
      {/* Background Circles Effect */}
      <div className="absolute flex items-center justify-center">
        <motion.div
          className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
          style={{ scale: scale1 }}
        />
        <motion.div
          className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
          style={{ scale: scale2 }}
        />
        <motion.div
          className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
          style={{ scale: scale3 }}
        />
      </div>

      {/* Hero Content */}
      <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
        <motion.h1
          className="text-3xl sm:text-4xl md:text-3xl font-bold mb-2 md:mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          QuickBooks Add-ons Hosting
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
          Transform Your Accounting Operations with ConciseNext’s Premier QuickBooks Add-ons Hosting
          Experience Unparalleled Security, Dependability, and Customization, Specifically Engineered for Accounting Experts
        </motion.p>
        <motion.a
          href="/free-trial"
          className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
        >
          Start Your 7 Day Free Trial
        </motion.a>
      </div>



    </div>
  );
};
const FAQs = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">FAQs – QuickBooks Add-Ons Hosting</h1>

      <div className="space-y-8">
        <div className="bg-blue-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
          <h2 className="text-2xl font-semibold text-blue-700">1. What are the advantages of using QuickBooks Add-Ons with licenses purchased from ConciseNext?</h2>
          <ul className="list-disc pl-5 text-gray-600">
            <li><strong>Seamless Integration:</strong> Our hosted QuickBooks environment ensures that all add-ons work smoothly with your QuickBooks software.</li>
            <li><strong>Automatic Updates:</strong> You receive real-time software updates and patches without manual intervention.</li>
            <li><strong>Enhanced Security:</strong> All your data remains encrypted and protected in our secure cloud environment.</li>
            <li><strong>Dedicated Support:</strong> Our QuickBooks hosting experts provide round-the-clock support to resolve any technical issues.</li>
            <li><strong>Scalability:</strong> Easily add or remove add-ons as per your business needs.</li>
          </ul>
        </div>

        <div className="bg-green-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
          <h2 className="text-2xl font-semibold text-green-700">2. How can QuickBooks Add-Ons be installed on a cloud-hosting platform?</h2>
          <p className="text-gray-600">
            Our cloud hosting team ensures a hassle-free setup for QuickBooks Add-Ons. The process includes:
          </p>
          <ol className="list-decimal pl-8 text-gray-600">
            <li><strong>Software Compatibility Check:</strong> Ensuring the selected add-ons are compatible with your QuickBooks version.</li>
            <li><strong>Server Configuration:</strong> Setting up a cloud server optimized for QuickBooks performance.</li>
            <li><strong>Installation & Integration:</strong> Installing the add-ons, integrating them with QuickBooks, and testing their functionality.</li>
            <li><strong>User Access & Security Setup:</strong> Configuring user roles and permissions to ensure secure access.</li>
          </ol>
        </div>

        <div className="bg-yellow-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
          <h2 className="text-2xl font-semibold text-yellow-700">3. What steps are required to configure multi-user access for QuickBooks Add-Ons?</h2>
          <p className="text-gray-600">
            To enable multi-user access for QuickBooks Add-Ons on the cloud, we follow these steps:
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li><strong>Setting Up User Roles:</strong> Assigning user permissions based on job functions.</li>
            <li><strong>Enabling Simultaneous Access:</strong> Configuring QuickBooks hosting settings to allow multiple users to work on the same file without conflicts.</li>
            <li><strong>Data Synchronization:</strong> Ensuring that all add-ons update in real time for accurate reporting.</li>
            <li><strong>Security & Compliance:</strong> Implementing secure logins, encryption, and audit logs for activity tracking.</li>
          </ul>
        </div>

        <div className="bg-red-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
          <h2 className="text-2xl font-semibold text-red-700">4. What are the costs associated with hosting QuickBooks Add-Ons on the cloud?</h2>
          <p className="text-gray-600">
            The cost of hosting QuickBooks Add-Ons depends on several factors, including:
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li><strong>Number of Users:</strong> More users require additional resources and licenses.</li>
            <li><strong>Storage & Processing Power:</strong> Higher data storage and processing needs affect pricing.</li>
            <li><strong>Add-On Complexity:</strong> Advanced add-ons with extensive integration requirements may have higher costs.</li>
            <li><strong>Support & Maintenance:</strong> Pricing varies based on service level agreements (SLA) for support.</li>
          </ul>
          <p className="text-gray-600">
            ConciseNext offers flexible pricing plans tailored to your business needs. Contact us for a custom quote.
          </p>
        </div>
      </div>
    </div>
  );
};


const QuickBooksAddOns = () => {


  return (
    <div>
      <Helmet>
        <title>QuickBooks Add-ons Hosting Services | ConciseNext</title>
        <meta name="title" content="QuickBooks Add-ons Hosting Services | ConciseNext" />
        <meta name="focus_keyword" content="QuickBooks Add-ons Hosting" />
        <meta name="description" content="ConciseNext provides reliable QuickBooks Add-ons Hosting Services, boosting your QuickBooks experience with seamless integration and enhanced performance in the cloud." />
        <meta name="keywords" content="concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="ConciseNext: Your Cloud Application Hosting Solution" />
        <meta property="og:description" content="Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs." />
        <meta property="og:image" content="https://concisenext.com/public_image/webp/logocolor.481479ea418411f3e450.webp" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <Hero />
      <HostingSection />
      <QuickBooksAddOnsHost/>
      <div className="bg-white py-8">
        <div className="container mx-auto max-w-7xl">
          {features.map((feature, index) => (
            <SmoothScrollFeature key={feature.id} feature={feature} index={index} />
          ))}
        </div>
      </div>

      <DifferenceSection />
      <BenefitsSection />
      <AdditionalFeatures />
      <HowItWorks />
      <IndustriesWeServe />
      <FAQs />

    </div>
  );
};

export default QuickBooksAddOns;
