import React, { useEffect, useRef } from 'react';
import { ShieldCheckIcon, GlobeAltIcon, ClockIcon, ChartBarIcon, SupportIcon, CloudIcon } from '@heroicons/react/solid';
import { FaHandsHelping, FaCloudUploadAlt, FaTools, FaShieldAlt } from 'react-icons/fa'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import dataSec from "../assets/images/datasecurity.webp";
import multiUsr from "../assets/images/multiUsr.webp"
import accdata from "../assets/images/accesdata.webp"
import { Helmet } from 'react-helmet';

import dataBckp from "../assets/images/dataBackup.webp"
import relper from "../assets/images/relper.webp"
import techsuport from "../assets/images/techsup.webp"
import teamwrk from "../assets/images/teamwrk.webp"
import enterprise2 from "../assets/images/enterprise2.webp"
import etertprisefirst from "../assets/images/enterprisefirst.webp"
import enterprisesec from "../assets/images/enterprisesecu.webp"
import enterpriserem from "../assets/images/enterpriseremote.webp"
import enterpriseavl from "../assets/images/enterpriserelibilities.webp"
import posremote from "../assets/images/posremote.webp"
import posSecu from "../assets/images/possecurity.webp"
import posrel from "../assets/images/posrel.webp"
import posinteg from "../assets/images/postintegra.webp"
import poscost from "../assets/images/poscost.webp"
import addon from "../assets/images/qb/add-ons.webp"
import pos1 from "../assets/images/poshosting.webp"
import possup from "../assets/images/possuport.webp"
import adons1 from "../assets/images/addons1.webp"

import { FaUser, FaUsers, FaHdd, FaMemory, FaAppStore } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const features = [
  {
    id: 1,
    name: 'Canadian Tax Compliance',
    icon: ShieldCheckIcon,
    description: 'QuickBooks Canada is designed to comply with Canadian tax laws, including GST/HST, PST, and income tax requirements. Our experts help you navigate the complexities of tax compliance, ensuring your returns are accurate and timely.',
    image: posremote,
  },
  {
    id: 2,
    name: 'Bookkeeping and Accounting',
    icon: GlobeAltIcon,
    description: 'Keep your financial records accurate and up to date with our bookkeeping services. Our QuickBooks Canada experts take care of all aspects of your bookkeeping, including expense tracking, bank reconciliation, and financial reporting.',
    image: posSecu,
  },
  {
    id: 3,
    name: 'Payroll for Canadian Businesses',
    icon: ClockIcon,
    description: 'Payroll management can be challenging, but with QuickBooks Canada, it becomes straightforward. We help you set up and manage payroll while ensuring adherence to Canadian payroll regulations, including EI, CPP, and tax deductions.',
    image: posrel,
  },
  {
    id: 4,
    name: 'QuickBooks Setup and Training',
    icon: ChartBarIcon,
    description: 'Whether you are new to QuickBooks or migrating from another system, we provide comprehensive setup and training services tailored to your needs. Our team ensures your QuickBooks Canada software is configured correctly and that you’re comfortable using its features for your business.',
    image: posinteg,
  },
  {
    id: 11,
    name: 'Multi-Currency and French Language Support',
    icon: SupportIcon,
    description: 'If your business deals with multiple currencies or operates in Quebec, QuickBooks Canada’s multi-currency and French language support features are perfect for you. We’ll help you leverage these features to manage transactions and records efficiently.',
    image: teamwrk,
  },

];





const BenefitsSection = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
  const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white py-10">
      <div className="container mx-auto px-6">
        <h2 className={`text-3xl font-bold text-center mb-6 ${inView1 ? 'animate-fadeIn' : ''}`}>
          Benefits of QuickBooks Canada
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div
            ref={ref1}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView1 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Designed for Canadian Businesses</h3>
            <p className="mb-4 card-info">
              QuickBooks Canada includes all the features you need to manage your business while ensuring compliance with Canadian laws and regulations.
            </p>
            <img
              src={possup}
              alt="Accessibility"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
            // style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref2}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView2 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">	Real-Time Insights</h3>
            <p className="mb-4 card-info">
              Get a clear, real-time view of your business’s financial performance. With easy access to your financial data, you can make informed decisions that help your business grow.
            </p>
            <img
              src={poscost}
              alt="Backup and Recovery"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
            // style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref3}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView3 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Simplified Tax Filing</h3>
            <p className="mb-4 card-info">
              QuickBooks Canada automatically calculates GST/HST and other taxes, reducing the risk of errors and making tax filing simpler.
            </p>
            <img
              src={multiUsr}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
            // style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref4}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView4 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Remote Access and Multi-User Collaboration</h3>
            <p className="mb-4 card-info">
              Access your financial data anytime, anywhere, and collaborate with your accountant seamlessly. Our QuickBooks Canada services make it easy for your team to stay connected, regardless of location.
            </p>
            <img
              src={"https://quickbooks.intuit.com/oidam/intuit/sbseg/en_au/quickbooks-online/web/image/hero/sbseg-en_au-oa-retargeting-hero-rw--no-widget.png"}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
            // style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HostingSection = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 md:py-20 md:px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">
        {/* Header Section */}
        <div className="text-center mb-6 md:mb-16">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-3 md:mb-4">
            Quickbook Canada Made Easy for Canadian Businesses
          </h1>

        </div>
        {/* Content Section */}
        <div className="grid gap-10 md:gap-16 items-center grid-cols-1 md:grid-cols-2">
          {/* Text Content */}
          <div className="space-y-4 md:space-y-6">
            <p className="text-base md:text-xl text-gray-700 leading-relaxed">
              ConciseNext Solutions is proud to offer QuickBooks services specifically designed for Canadian businesses. From bookkeeping to tax preparation, our QuickBooks Canada services are customized to meet local compliance standards and help you manage your finances effectively and efficiently.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-2">
                <ShieldCheckIcon className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <span className="font-semibold text-gray-800">Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-6 w-6 md:h-8 md:w-8 text-green-600" />
                <span className="font-semibold text-gray-800">Reliable</span>
              </div>
              <div className="flex items-center space-x-2">
                <CloudIcon className="h-6 w-6 md:h-8 md:w-8 text-purple-600" />
                <span className="font-semibold text-gray-800">Scalable</span>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="relative">
            <img
              src={addon}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-60 md:h-80"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuickBooksProHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          QuickBooks Add-ons Hosting Pricing & Plans
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At <span className="font-bold">ConciseNext</span>, we offer flexible hosting plans designed to meet the unique needs of businesses of all sizes. Whether you're an individual user or part of a growing team, our plans provide the performance, security, and scalability you need to keep your accounting operations running smoothly.
        </motion.p>
      </div>
    </section>
  );
};

const StandardHostingPlan = () => {
  const standardPlans = [
    {
      title: '1-User Plan',
      price: '$33',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-purple-100',
    },
    {
      title: 'Per Additional User',
      price: '$30',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-pink-100',
    }
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">
          {standardPlans.map(plan => (
            <motion.div
              key={plan.title}
              className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
                <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
                <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
              </div>
              <div className="flex items-center justify-center text-gray-600">
                <FaHdd className="text-blue-600 mr-2" />
                <span>{plan.storage}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};



const industries = [
  {
    title: "Retail and E-commerce",
    description:
      "Integrate QuickBooks with your POS and inventory management tools for streamlined operations.",
    imageUrl:
      "https://cdn.pixabay.com/photo/2024/06/18/11/14/e-commerce-8837703_1280.jpg",
  },
  {
    title: "Construction and Real Estate",
    description:
      "Host project management add-ons to track job costs and manage budgets efficiently.",
    imageUrl:
      "https://images.unsplash.com/photo-1669003154400-053af9a35eba?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Healthcare",
    description:
      "Ensure compliance with HIPAA while managing payroll and billing add-ons in a secure environment.",
    imageUrl:
      "https://media.istockphoto.com/id/1903423742/photo/medical-team-meeting.jpg?s=2048x2048&w=is&k=20&c=qK4SGw8THH8cH4fqDF5I9B1tepCdoHVZf6N_KC-DzYI=",
  },
  {
    title: "Manufacturing",
    description:
      "Utilize production and inventory add-ons to keep track of materials, costs, and workflows.",
    imageUrl:
      "https://media.istockphoto.com/id/950229764/photo/factory-operations-in-full-work.jpg?s=2048x2048&w=is&k=20&c=ceaBAaIy3Cw5htOEyYQPukfaNT2yK4v5ykA8WeBz1UU=",
  },
  {
    title: "Professional Services",
    description:
      "Integrate CRM and time-tracking tools with QuickBooks to enhance client management and billing.",
    imageUrl:
      "https://cdn.pixabay.com/photo/2024/06/22/18/09/ai-generated-8846758_960_720.jpg",
  },
  {
    title: "Education",
    description:
      "Manage student accounts, track donations, and streamline administrative tasks with QuickBooks tailored for educational institutions.",
    imageUrl:
      "https://cdn.pixabay.com/photo/2015/07/28/22/05/child-865116_1280.jpg"
  },
];


const IndustriesWeServe = () => {
  return (
    <div className="max-w-6xl mx-auto p-6">

      <div className="text-center mt-12 border-1 p-4">
        <h3 className="text-3xl font-bold mb-4">
          Get Started Today with QuickBooks Canada
        </h3>
        <p className="mb-8">
          Let ConciseNext Solutions simplify your financial management with our specialized QuickBooks Canada services. Whether you’re just starting out or looking to optimize your existing setup, we’re here to help you every step of the way.
        </p>
        <p className="mb-8">
          <b>Contact Us</b> today to learn more about our QuickBooks Canada services and how we can support your business in managing finances effectively, staying compliant, and achieving success.
        </p>
        <Link to={"/contact-us"} className="bg-button text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors">
          Contact Us
        </Link>
      </div>
    </div>
  );
};


const HowItWorks = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });


  const fadeInClass = (inView) =>
    inView ? 'opacity-100 translate-y-0 scale-100' : 'opacity-0 translate-y-8 scale-95';

  return (
    <div className="max-w-5xl mx-auto p-3">
      <h2 className="text-4xl font-bold text-center mb-8">
        Why Choose ConciseNext Solutions?
      </h2>

      <div className={`flex items-start mb-8 transition-transform duration-[1000ms] ${fadeInClass(inView1)}`} ref={ref1}>
        <FaHandsHelping className="text-4xl text-blue-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">1. Expert Local Support</h3>
          <p>
            Our team understands the unique needs of Canadian businesses. With our local expertise and experience, we provide support that’s customized to your business and the Canadian regulatory landscape.
          </p>
        </div>
      </div>

      <div className={`flex items-start mb-8 transition-transform duration-[1000ms] ${fadeInClass(inView2)}`} ref={ref2}>
        <FaCloudUploadAlt className="text-4xl text-green-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold mb-2">2. End-to-End QuickBooks Assistance</h3>
          <p>
            From initial setup and customization to ongoing bookkeeping and support, our services cover every aspect of QuickBooks Canada. We ensure you get the most out of the software, saving you time and helping you grow your business.
          </p>
        </div>
      </div>

      <div className={`flex items-start  transition-transform duration-[1000ms] ${fadeInClass(inView3)}`} ref={ref3}>
        <FaTools className="text-4xl text-yellow-600 mr-4" />
        <div>
          <h3 className="text-2xl text-[#3636b9] font-semibold ">3. Commitment to Your Success</h3>
          <p>
            We work closely with our clients to understand their financial needs and challenges, providing personalized support and insights that help your business thrive.
          </p>
        </div>
      </div>

    </div>
  );
};


const AdditionalFeatures = () => {
  // Hook for triggering animations on scroll
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref6, inView6] = useInView({ triggerOnce: true, threshold: 0.1 });

  const fadeInClass = (inView) =>
    inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8';

  return (
    <div className="max-w-5xl mx-auto p-6">
      <h2 className="text-4xl font-bold text-center mb-8">
        Who Can Benefit from Our QuickBooks Canada Services?
      </h2>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView1)}`} ref={ref1}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">1. Small and Medium-Sized Enterprises (SMEs): </h3>
        <p className="mb-8">
          QuickBooks Canada is ideal for SMEs that need a cost-effective, easy-to-use accounting solution designed specifically for the Canadian market.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView2)}`} ref={ref2}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">2. Freelancers and Contractors</h3>
        <p className="mb-8">
          Independent professionals looking for a simple way to manage their income, track expenses, and stay on top of tax obligations.
        </p>
      </div>

      <div className={`transition-transform duration-[1000ms] ${fadeInClass(inView3)}`} ref={ref3}>
        <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">3. Nonprofits and Startups</h3>
        <p className="mb-8">
          New businesses and nonprofit organizations that need expert support in setting up QuickBooks Canada to handle finances effectively from the start.
        </p>
      </div>

    </div>
  );
};


const DifferenceSection = () => {
  return (
    <div className="relative py-5 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800 via-blue-500 to-purple-300 opacity-1 "></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">Why QuickBooks Canada for Your Business?</h2>
        <p className="text-lg leading-relaxed mb-8">
          QuickBooks Canada is more than just accounting software; it is a powerful tool that helps Canadian businesses stay on top of their finances, streamline accounting processes, and ensure compliance with Canadian regulations. Our QuickBooks services provide end-to-end support, so you can focus on growing your business while we take care of your financial management needs.
        </p>

      </div>
    </div>
  );
};

const SmoothScrollFeature = ({ feature, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  useEffect(() => {
    if (inView && window.innerWidth > 768) {  // Check if screen is larger than 768px
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={window.innerWidth > 768 ? controls : 'visible'}  // Disable animation on small screens
      variants={{
        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.7, ease: 'easeOut' }}
      className="grid md:grid-cols-2 gap-8 xl:gap-20 items-center py-8"
    >
      {/* Image */}
      <div className={`${index % 2 === 0 ? 'order-last md:order-first' : ''}`}>
        <img
          src={feature.image}
          alt={feature.name}
          className="rounded-lg shadow-lg transform hover:scale-105 h-[auto] w-[400px] xl:h-[auto] xl:w-[600px] transition duration-500"
        />
      </div>
      {/* Text */}
      <div>
        <h3 className="text-2xl font-bold text-gray-800 mb-4">{feature.name}</h3>
        <p className="text-lg text-gray-700 leading-relaxed">{feature.description}</p>
      </div>
    </motion.div>
  );
};

const Hero = () => {
  const { scrollY } = useScroll();
  const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
  const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
  const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
  const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <div className="relative h-[500px] md:h-[600px]  bg-[url('https://cdn.pixabay.com/photo/2014/12/27/15/40/office-581131_1280.jpg')] bg-cover bg-center bg-no-repeat overflow-hidden">
      {/* Background Circles Effect */}
      <div className="absolute flex items-center justify-center">
        <motion.div
          className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
          style={{ scale: scale1 }}
        />
        <motion.div
          className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
          style={{ scale: scale2 }}
        />
        <motion.div
          className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
          style={{ scale: scale3 }}
        />
      </div>

      {/* Hero Content */}
      <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
        <motion.h1
          className="text-3xl sm:text-4xl md:text-3xl font-bold mb-2 md:mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          ConciseNext Solutions: QuickBooks Canada Services
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
          ConciseNext Solutions: Simplifying QuickBooks for Canadian businesses with expert cloud hosting, setup, and support. Streamline your accounting securely and efficiently with us.
        </motion.p>
        <motion.a
          href="#"
          className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
        >
          Start Your 7 Day Free Trial
        </motion.a>
      </div>



    </div>
  );
};

const QuickBooksCanada = () => {

  return (
    <div>
      <Helmet>
        {/* Document Title */}
        <title>QuickBooks Canada | Download Quickbooks Desktop Ssoftware | ConciseNext</title>

        {/* Meta Tags */}
        <meta name="title" content="QuickBooks Canada | Download Quickbooks Desktop Ssoftware | ConciseNext" />
        <meta name="focus_keyword" content="Quickbook Canada" />
        <meta name="description" content="ConciseNext offers reliable desktop QuickBooks software in Canada. Streamline your accounting processes by  downloading your QuickBooks version. " />
        <meta name="keywords" content="concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management" />
        <meta name="robots" content="index, follow" />

        {/* Canonical Link */}
        <link rel="canonical" href={window.location.href} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="ConciseNext: Your Cloud Application Hosting Solution" />
        <meta property="og:description" content="Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs." />
        <meta property="og:image" content="https://concisenext.com/public_image/webp/logocolor.481479ea418411f3e450.webp" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <Hero />
      <HostingSection />
      <div className="bg-white py-8">
        <div className="container mx-auto max-w-7xl">
          {features.map((feature, index) => (
            <SmoothScrollFeature key={feature.id} feature={feature} index={index} />
          ))}
        </div>
      </div>

      <DifferenceSection />
      <BenefitsSection />
      <AdditionalFeatures />
      <HowItWorks />
      <IndustriesWeServe />

    </div>
  );
};

export default QuickBooksCanada;
