import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link  } from "react-router-dom";
import qb from '../../assets/images/qb.webp'

const QuickbooksDropdown = () => {
    return (
        <div className="flex justify-center ">
            <FlyoutLink FlyoutContent={PricingContent}>
                QuickBooks Solution
            </FlyoutLink>
        </div>
    );
};

const FlyoutLink = ({ children, href, FlyoutContent }) => {
    const [open, setOpen] = useState(false);

    const showFlyout = FlyoutContent && open;

    return (
        <div
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            className="relative w-fit h-fit"
        >
            <a href={href} className="relative text-white text-pretty">
                {children}
                <span
                    style={{
                        transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
                    }}
                    className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-indigo-300 transition-transform duration-300 ease-out"
                />
            </a>
            <AnimatePresence>
                {showFlyout && (
                    <motion.div
                        initial={{ opacity: 0, y: 15 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 15 }}
                        style={{ translateX: "-50%" }}
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        className="absolute left-1/2 top-12 bg-white text-black"
                    >
                        <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
                        <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
                        <FlyoutContent />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const PricingContent = () => {
    return (
        <div className=" w-128 sub-menu flex flex-row justify-between items-center bg-white p-7 shadow-xl">
            <div className="space-y-5 mx-2">
                <Link to={"/quickbooks-hosting"} className="block  ">QuickBook Hosting</Link>
                <Link  to ={"/quickbooks-pro-hosting"} className="block   ">QuickBook Pro Hosting</Link>
                <Link to ={"quickbooks-premier-hosting"} className="block  ">QuickBooks Premier Hosting</Link>
                <Link to ={"/quickbooks-enterprise-hosting"}  className="block  ">QuickBooks Enterprise Hosting</Link>
                <Link to={"/quickbooks-pos-hosting"} className="block ">QuickBooks POS Hosting</Link>
                <Link to ={"/quickbooks-add-ons-hosting"} className="block   ">QuickBooks Add-ons Hosting</Link>
                {/* <Link to ={"/buy-quickbooks"} className="block " >Buy QuickBooks</Link> */}
                <Link to ={"/quickbooks-accountant"} className="block " >QuickBooks Accountant</Link>
                <Link to ={"/quickbooks-canada"} className="block " >QuickBooks Canada</Link>
                
            </div>
            <div>
                <img src={qb} height="150" width="150" />
            </div>
        </div>
    );
};

export default QuickbooksDropdown;