import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaCog, FaCreditCard, FaEnvelope, FaChartLine, FaUserEdit, FaEnvelopeOpen, FaCookieBite, FaExternalLinkAlt, FaSyncAlt, FaTrashAlt, FaLock, FaShieldAlt, FaDatabase } from 'react-icons/fa';
import "../styles/aboutus.css";
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';

const PrivacyPolicy = () => {

    const { scrollY } = useScroll();
    const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
    const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
    const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const data = [
        {
            title: 'Service Providers',
            content:
                'We may employ third-party companies and individuals to facilitate our services, process payments, or provide support. These third parties will have access to your personal information only to perform tasks on our behalf and are obligated not to disclose or use it for any other purpose.',
        },
        {
            title: 'Legal Requirements',
            content:
                'We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).',
        },
        {
            title: 'Business Transfers',
            content:
                'In the event of a merger, acquisition, or asset sale, your personal information may be transferred as part of that transaction. We will notify you before your personal information is transferred and becomes subject to a different privacy policy.',
        },
    ];



    const sections = [
        {
            title: 'Cookies and Tracking Technologies',
            icon: <FaCookieBite className="text-yellow-600 text-3xl" />,
            content:
                'We may use cookies and similar tracking technologies to monitor activity on our services and store certain information. Cookies are files with small amounts of data that may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.',
            bgColor: 'bg-yellow-50',
            textColor: 'text-yellow-700',
        },
        {
            title: 'Third-Party Links',
            icon: <FaExternalLinkAlt className="text-blue-600 text-3xl" />,
            content:
                'Our services may contain links to third-party websites that are not operated by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites. We recommend that you review the privacy policy of every site you visit.',
            bgColor: 'bg-blue-50',
            textColor: 'text-blue-700',
        },
        {
            title: 'Changes to This Privacy Policy',
            icon: <FaSyncAlt className="text-green-600 text-3xl" />,
            content:
                'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date. We encourage you to review this Privacy Policy periodically for any changes. Your continued use of our services after any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified policy.',
            bgColor: 'bg-green-50',
            textColor: 'text-green-700',
        },
    ];



    return (
        <div className="bg-gray-50 text-gray-800">
            {/* Hero Section */}
            <div className="relative bg-[url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0DeTPLl0yCKSvhMMrFs0EsHMgif2-2IlJrQ&s')] bg-center h-[400px] md:h-[500px] bg-no-repeat bg-cover overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-32 h-32 md:w-64 md:h-64 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-48 md:h-48 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-16 h-16 md:w-32 md:h-32 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
                    <motion.h1
                        className="text-2xl sm:text-3xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        QuickBooks Hosting Privacy Policy
                    </motion.h1>
                    <motion.p
                        className="text-xs sm:text-sm md:text-lg max-w-sm md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        At ConciseNext Solutions, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you utilize our QuickBooks Hosting services. By using our services, you agree to the terms of this policy.
                    </motion.p>
                </div>
            </div>

            {/* Information Section */}
            <section className="bg-gray-100 p-6 sm:p-8 rounded-lg w-full max-w-5xl mx-auto my-10">
                <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8 text-center">Information We Collect</h2>
                <p className="text-gray-700 text-center mb-8 sm:mb-10 max-w-lg mx-auto">
                    We may collect various types of information when you use our QuickBooks Hosting services, including:
                </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
                    {/* Personal Information Card */}
                    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 flex flex-col items-center">
                        <h3 className="text-lg sm:text-xl font-semibold text-blue-600 mb-2">Personal Information</h3>
                        <p className="text-gray-600 text-center">
                            Information that can identify you, such as your name, email address, phone number, and billing details.
                        </p>
                    </div>

                    {/* Account Information Card */}
                    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 flex flex-col items-center">
                        <h3 className="text-lg sm:text-xl font-semibold text-blue-600 mb-2">Account Information</h3>
                        <p className="text-gray-600 text-center">
                            Information related to your account, including your username, password, and account preferences.
                        </p>
                    </div>

                    {/* Usage Data Card */}
                    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 flex flex-col items-center">
                        <h3 className="text-lg sm:text-xl font-semibold text-blue-600 mb-2">Usage Data</h3>
                        <p className="text-gray-600 text-center">
                            Information about how you access and use our services, including your IP address, browser type, and access times.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 p-6 sm:p-8 rounded-lg w-full max-w-5xl mx-auto my-10">
                <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8 text-center">How We Use Your Information</h2>
                <p className="text-gray-700 text-center mb-8 sm:mb-10 max-w-lg mx-auto">
                    We use the information we collect for various purposes, including:
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
                    {/* Purpose Cards */}
                    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 flex flex-col items-center">
                        <FaCog className="text-blue-500 text-2xl sm:text-3xl mb-3" />
                        <h3 className="text-lg sm:text-xl font-semibold text-blue-600 mb-2">Provide & Improve Services</h3>
                        <p className="text-gray-600 text-center">
                            To provide, maintain, and improve our QuickBooks Hosting services.
                        </p>
                    </div>

                    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 flex flex-col items-center">
                        <FaCreditCard className="text-green-500 text-2xl sm:text-3xl mb-3" />
                        <h3 className="text-lg sm:text-xl font-semibold text-green-600 mb-2">Process Transactions</h3>
                        <p className="text-gray-600 text-center">
                            To process your transactions and manage your account.
                        </p>
                    </div>

                    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 flex flex-col items-center">
                        <FaEnvelope className="text-purple-500 text-2xl sm:text-3xl mb-3" />
                        <h3 className="text-lg sm:text-xl font-semibold text-purple-600 mb-2">Communicate with You</h3>
                        <p className="text-gray-600 text-center">
                            To communicate with you, including sending service-related updates and marketing communications.
                        </p>
                    </div>

                    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 flex flex-col items-center">
                        <FaChartLine className="text-indigo-500 text-2xl sm:text-3xl mb-3" />
                        <h3 className="text-lg sm:text-xl font-semibold text-indigo-600 mb-2">Analyze Usage & Trends</h3>
                        <p className="text-gray-600 text-center">
                            To monitor the usage of our services and analyze trends to enhance user experience.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 p-6 sm:p-8 rounded-lg max-w-3xl mx-auto my-10">
                <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8 text-center">Data Sharing and Disclosure</h2>
                <p className="text-gray-700 text-center mb-8 sm:mb-10 max-w-lg mx-auto">
                    We do not sell, rent, or lease your personal information to third parties. However, we may share your information in the following circumstances:
                </p>

                <div className="space-y-4">
                    {data.map((item, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-lg">
                            <button
                                className="w-full flex justify-between items-center p-4 sm:p-6 text-left"
                                onClick={() => toggleAccordion(index)}
                            >
                                <h3 className="text-lg sm:text-xl font-semibold text-blue-600">{item.title}</h3>
                                {activeIndex === index ? (
                                    <FaChevronUp className="text-blue-600 text-lg" />
                                ) : (
                                    <FaChevronDown className="text-blue-600 text-lg" />
                                )}
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 sm:p-6 text-gray-600 border-t border-gray-200">
                                    {item.content}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>



            <section className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-16 px-4 sm:px-8 lg:px-16 w-full">
                <div className="max-w-7xl mx-auto text-center">
                    <h2 className="text-3xl sm:text-4xl font-bold mb-6">Data Security</h2>
                    <p className="text-lg sm:text-xl max-w-3xl mx-auto mb-8">
                        We prioritize the security of your personal information and use reasonable measures to protect it from unauthorized access, theft, and loss. These measures include encryption, firewalls, and secure data storage. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8 max-w-5xl mx-auto">
                    {/* Encryption Card */}
                    <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center">
                        <FaLock className="text-blue-600 text-4xl mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Encryption</h3>
                        <p className="text-center">
                            All sensitive data is encrypted during transmission and storage, ensuring your information remains secure.
                        </p>
                    </div>

                    {/* Firewalls Card */}
                    <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center">
                        <FaShieldAlt className="text-blue-600 text-4xl mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Firewalls</h3>
                        <p className="text-center">
                            Our systems are protected by advanced firewalls that prevent unauthorized access and monitor potential threats.
                        </p>
                    </div>

                    {/* Secure Data Storage Card */}
                    <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center">
                        <FaDatabase className="text-blue-600 text-4xl mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Secure Data Storage</h3>
                        <p className="text-center">
                            We use secure data storage solutions to safeguard your personal information from loss or theft.
                        </p>
                    </div>
                </div>
            </section>



            <section className="bg-gray-50 py-16 px-4 sm:px-8 lg:px-16 w-full">
                <div className="max-w-7xl mx-auto">
                    <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-800 mb-8">Your Rights and Choices</h2>
                    <p className="text-lg sm:text-xl text-center text-gray-600 mb-10 max-w-2xl mx-auto">
                        You have the following rights regarding your personal information:
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Access and Update Card */}
                        <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center">
                            <FaUserEdit className="text-blue-600 text-4xl mb-4" />
                            <h3 className="text-xl font-semibold text-gray-700 mb-2">Access and Update</h3>
                            <p className="text-gray-600">
                                You can access and update your personal information at any time by logging into your account or contacting us.
                            </p>
                        </div>

                        {/* Opt-Out Card */}
                        <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center">
                            <FaEnvelopeOpen className="text-green-600 text-4xl mb-4" />
                            <h3 className="text-xl font-semibold text-gray-700 mb-2">Opt-Out</h3>
                            <p className="text-gray-600">
                                You may opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications.
                            </p>
                        </div>

                        {/* Data Deletion Card */}
                        <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center">
                            <FaTrashAlt className="text-red-600 text-4xl mb-4" />
                            <h3 className="text-xl font-semibold text-gray-700 mb-2">Data Deletion</h3>
                            <p className="text-gray-600">
                                You can request the deletion of your personal information, subject to our legal obligations to retain certain information.
                            </p>
                        </div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-16 px-4 sm:px-8 lg:px-20 w-full">
                <div className="max-w-7xl mx-auto">
                    <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">Additional Information</h2>

                    <div className="space-y-8">
                        {sections.map((section, index) => (
                            <div key={index} className={`rounded-lg shadow-lg overflow-hidden ${section.bgColor}`}>
                                {/* Header */}
                                <div className={`flex items-center ${section.textColor} py-5 px-6`}>
                                    {section.icon}
                                    <h3 className="ml-4 text-2xl font-semibold">{section.title}</h3>
                                </div>

                                {/* Content */}
                                <div className="px-6 py-6 text-gray-700">
                                    <p className="text-lg leading-relaxed">{section.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>




            <section className="py-16 px-8 bg-gradient-to-r from-blue-400 to-blue-600 text-white">
                <div className="max-w-7xl mx-auto text-center">
                    <h2 className="text-4xl font-bold mb-6">Contact Us</h2>
                    <p className="text-lg mb-8">
                        If you have any questions about this Privacy Policy, please contact us
                    </p>

                    <div className="flex flex-col items-center mb-6">
                        {/* Contact Information Card */}
                        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
                            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Contact Information</h3>
                            <div className="mb-4">
                                <p className="flex items-center text-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-green-500 mr-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7h18M3 12h18m-6 5h6" />
                                    </svg>
                                    <span>Email: <a href="mailto:xyzabc@gmail.com" className="text-green-500 hover:underline">support@concisenext.com</a></span>
                                </p>
                            </div>
                            <div className="mb-4">
                                <p className="flex items-center text-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-green-500 mr-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                    </svg>
                                    <span>Phone: <a href="tel:+18178036797" className="text-green-500 hover:underline">+1 (817) 803-6797</a></span>
                                </p>
                            </div>
                            <div className="flex items-center text-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-green-500 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                </svg>
                                <span>Address: <span className="text-gray-600">CONCISENEXT INFOTECH LLC<br />
                                    5900, BALCONES DRIVE, STE. 100;<br />
                                    AUSTIN, TEXAS-78731</span></span>
                            </div>
                        </div>
                    </div>

                    <p className="text-lg max-w-2xl mx-auto">
                        Thank you for choosing ConciseNext Solutions for your QuickBooks Hosting needs!
                    </p>
                </div>
            </section>

        </div>
    )
}

export default PrivacyPolicy;
