import React, { useEffect, useRef } from 'react';
import { ShieldCheckIcon, GlobeAltIcon, ClockIcon, ChartBarIcon, SupportIcon, CloudIcon } from '@heroicons/react/solid';
import { motion, useAnimation, useTransform, useScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import dataSec from "../assets/images/datasecurity.webp";
import multiUsr from "../assets/images/multiUsr.webp"
import accdata from "../assets/images/accesdata.webp"
import dataBckp from "../assets/images/dataBackup.webp"
import relper from "../assets/images/relper.webp"
import techsuport from "../assets/images/techsup.webp"
import teamwrk from "../assets/images/teamwrk.webp"
import enterprise2 from "../assets/images/qb/enterprise.webp"
import etertprisefirst from "../assets/images/enterprisefirst.webp"
import enterprisesec from "../assets/images/enterprisesecu.webp"
import enterpriserem from "../assets/images/enterpriseremote.webp"
import enterpriseavl from "../assets/images/enterpriserelibilities.webp"
import enterprisesup from "../assets/images/enterprisesup.webp"
import { Helmet } from 'react-helmet';

import { FaUser, FaUsers, FaHdd, FaMemory, FaAppStore } from 'react-icons/fa';

const features = [
  {
    id: 1,
    name: 'Superior Remote Access',
    icon: ShieldCheckIcon,
    description: 'With ConciseNext’s QuickBooks Enterprise hosting, you can access your accounting data from any location, on any device. Whether you’re at your desk or on the go, our cloud hosting enables seamless access to your critical business information, helping you stay productive no matter where you are.',
    image: enterpriserem,
  },
  {
    id: 2,
    name: 'Advanced Security Protocols',
    icon: GlobeAltIcon,
    description: 'Protect your sensitive financial data with our top-of-the-line security measures. ConciseNext utilizes sophisticated encryption, multi-factor authentication, and secure data centers to ensure that your information is safeguarded against unauthorized access and cyber threats.',
    image: enterprisesec,
  },
  {
    id: 3,
    name: 'High Availability and Reliability',
    icon: ClockIcon,
    description: 'Experience uninterrupted service with ConciseNext’s 99.9% uptime guarantee. Our QuickBooks Enterprise hosting infrastructure is built for reliability, ensuring your business operations run smoothly without the risk of downtime that could disrupt your workflow.',
    image: enterpriseavl,
  },
  {
    id: 4,
    name: 'Scalable Hosting Solutions',
    icon: ChartBarIcon,
    description: 'As your business grows, your hosting needs may evolve. ConciseNext offers scalable QuickBooks Enterprise hosting solutions that can easily adapt to your expanding requirements, allowing you to add users, increase storage, or upgrade resources without any hassle.',
    image: enterprisesup,
  },
  {
    id: 11,
    name: 'Streamlined Collaboration',
    icon: SupportIcon,
    description: 'Facilitate better teamwork with our cloud-based QuickBooks Enterprise hosting. ConciseNext’s hosting allows multiple users to work on the same data files simultaneously, improving collaboration and enhancing your team’s productivity.',
    image: teamwrk,
  },
  {
    id: 16,
    name: 'Cost-Efficient and Hassle-Free',
    icon: CloudIcon,
    description: 'Save on IT costs and eliminate the need for expensive on-premise servers with ConciseNext’s cost-effective hosting solutions. Our QuickBooks Enterprise hosting allows you to focus on your core business activities while we manage the technical infrastructure.',
    image: relper,
  },
];





const BenefitsSection = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white py-10">
      <div className="container mx-auto px-6">
        <h2 className={`text-3xl font-bold text-center mb-6 ${inView1 ? 'animate-fadeIn' : ''}`}>
        Key Benefits You Get With QuickBooks Enterprise Hosting Services

        </h2>
        <div className="container mx-auto p-6">
      
      <ul className="space-y-4">
        <li className="flex items-start">
          
          <span className="text-blue-600 mr-">✔️</span>
          <span className="text-lg">Anywhere, Anytime Access – With cloud hosting, you can access your QuickBooks POS system from any device, enabling real-time sales and inventory management even when you're away from your store.</span>
        </li>
        <li className="flex items-start">
          <span className="text-blue-600 mr-3">✔️</span>
          <span className="text-lg">Advanced Security & Data Protection – Our cloud hosting ensures enterprise-grade security with multi-layer encryption, firewalls, and automatic daily backups. Your financial data is safeguarded against cyber threats, unauthorized access, and data loss.</span>
        </li>
        <li className="flex items-start">
          <span className="text-blue-600 mr-3">✔️</span>
          <span className="text-lg">Seamless Multi-User Collaboration – Enable multiple users to work on QuickBooks Enterprise simultaneously with real-time data synchronization. Set custom user permissions to control access and enhance workflow efficiency.
</span>
        </li>
        <li className="flex items-start">
          <span className="text-blue-600 mr-3">✔️</span>
          <span className="text-lg">High Performance & Reliability–Enjoy a lag-free, high-speed experience with our optimized cloud infrastructure. Our hosting services ensure 99.99% uptime, reducing downtime risks and improving operational efficiency. </span>
        </li>
        <li className="flex items-start">
          <span className="text-blue-600 mr-3">✔️</span>
          <span className="text-lg">Scalability to Meet Business Needs – Easily scale your hosting plan as your business grows. Whether you need additional storage, processing power, or new user access, our flexible cloud solutions adapt to your changing requirements.
</span>
        </li>
        <li className="flex items-start">
          <span className="text-blue-600 mr-3">✔️</span>
          <span className="text-lg">Improved Performance & Speed – Enjoy high-speed processing with our optimized cloud servers, ensuring quick transaction processing and smooth POS operations.</span>
        </li>
        <li className="flex items-start">
          <span className="text-blue-600 mr-3">✔️</span>
          <span className="text-lg">Full Compatibility with Third-Party Integrations– Integrate QuickBooks Enterprise with essential business tools such as CRM software, inventory management systems, and payroll applications. Our cloud environment supports seamless app compatibility and smooth workflow automation.</span>
        </li>
        <li className="flex items-start">
          <span className="text-blue-600 mr-3">✔️</span>
          <span className="text-lg">Automatic Updates & Maintenance – Stay up to date with the latest QuickBooks Enterprise features and security patches without worrying about manual updates. Our team handles all software updates and server maintenance to ensure optimal performance.</span>
        </li>
        <li className="flex items-start">
          <span className="text-blue-600 mr-3">✔️</span>
          <span className="text-lg"> Dedicated 24/7 Support – Our expert support team is available round-the-clock to assist with any technical issues, setup concerns, or performance optimizations. Get quick resolutions and uninterrupted access to your QuickBooks Enterprise environment.</span>
        </li>
      </ul>
    </div>

        <div className="grid md:grid-cols-3 gap-8">
          <div
            ref={ref1}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView1 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Expert Support, Anytime You Need It</h3>
            <p className="mb-4 card-info">
              Our dedicated support team is available 24/7 to assist you with any issues or inquiries. ConciseNext’s QuickBooks Enterprise specialists are committed to providing you with prompt and reliable support, ensuring your business operations continue without interruption
            </p>
            <img
              src={techsuport}
              alt="Accessibility"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref2}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView2 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Cost-Effective Hosting Solutions</h3>
            <p className="mb-4 card-info">
              Reduce your IT expenses with ConciseNext’s affordable QuickBooks Enterprise hosting. By hosting your QuickBooks Enterprise in the cloud, you eliminate the need for costly in-house servers and IT maintenance, allowing you to focus on what matters most—growing your business.
            </p>
            <img
              src={dataBckp}
              alt="Backup and Recovery"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref3}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView3 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Multi-User Collaboration</h3>
            <p className="mb-4 card-info">
              Collaborate with your team in real-time. With QuickBooks hosting by ConciseNext, multiple users can access and work on the same files simultaneously.
            </p>
            <img
              src={multiUsr}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


const QuickBooksPremierHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
         QuickBooks Enterprise Hosting Pricing & Plans
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
         Choose a plan that best fits your business needs. All plans include high-performance cloud hosting, robust security, and 24/7 expert support.

        </motion.p>
      </div>
    </section>
  );
};

const StandardHostingPrePlan = () => {
  const standardPlans = [
    {
      title: '1-User Plan',
      price: '$33',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-purple-100',
    },
    {
      title: 'Per Additional User',
      price: '$30',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-pink-100',
    }
  ];

  return (
    <section className="py-14 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">
          {standardPlans.map(plan => (
            <motion.div
              key={plan.title}
              className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
                <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
                <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
              </div>
              <div className="flex items-center justify-center text-gray-600">
                <FaHdd className="text-blue-600 mr-2" />
                <span>{plan.storage}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const HostingSection = () => {
  return (
    <div className="bg-gray-100 py-6 px-4 md:py-20 md:px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">
        {/* Header Section */}
        <div className="text-center mb-6 md:mb-16">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-3 md:mb-4">
          Quickbooks Enterprise Hosting on the Cloud
          </h1>
          <p className="text-base md:text-lg text-gray-600">
            Empower Your Business with Advanced Cloud Solutions
          </p>
        </div>
        {/* Content Section */}
        <div className="grid gap-10 md:gap-16 items-center grid-cols-1 md:grid-cols-2">
          {/* Text Content */}
          <div className="space-y-4 md:space-y-6">
            <p className="text-base md:text-xl text-gray-700 leading-relaxed">
              ConciseNext offers robust QuickBooks Enterprise hosting services, designed to meet the complex needs of large businesses and accounting professionals. Our cloud hosting solutions provide a secure, scalable, and accessible environment for your QuickBooks Enterprise software, ensuring you can manage your business operations with greater efficiency and flexibility.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-2">
                <ShieldCheckIcon className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <span className="font-semibold text-gray-800">Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-6 w-6 md:h-8 md:w-8 text-green-600" />
                <span className="font-semibold text-gray-800">Reliable</span>
              </div>
              <div className="flex items-center space-x-2">
                <CloudIcon className="h-6 w-6 md:h-8 md:w-8 text-purple-600" />
                <span className="font-semibold text-gray-800">Scalable</span>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="relative">
            <img
              src={enterprise2}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-60 md:h-80"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuickBooksProHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          What is QuickBooks Enterprise with Cloud Access?
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          QuickBooks Enterprise with cloud access enables businesses to manage their
           accounting operations from anywhere with secure, remote accessibility. 
           Hosted on the cloud, it eliminates the need for on-premise infrastructure while providing 
           seamless collaboration, multi-user access, and real-time data synchronization.
            With advanced features like enhanced reporting, inventory management, and scalability, 
            ConciseNext’s QuickBooks Enterprise Hosting ensures high performance, data security, 
            and 24/7 accessibility,
           making accounting more efficient and hassle-free.</motion.p>
      </div>
    </section>
  );
};

const QuickBooksCloudEnterprise  = () => {
  return (
    <section className="bg-gradient-to-r from-yellow-800 via-yellow-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Why Choose QuickBooks Cloud Enterprise Hosting? 
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >QuickBooks Cloud Enterprise Hosting offers unparalleled flexibility, 
        security, and performance. By migrating to the cloud, businesses can access
         their accounting software from anywhere, collaborate in real time, 
         and eliminate the limitations of local hardware. Our hosting ensures automatic backups, 
         multi-user accessibility, seamless third-party integrations, and enterprise-grade security
          to protect financial data. With scalable resources and 24/7 expert support,
           we help businesses optimize operations and improve financial efficiency.
        </motion.p>
      </div>
    </section>
  );
};





const StandardHostingPlan = () => {
  const standardPlans = [
    {
      title: '1-User Plan',
      price: '$33',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-purple-100',
    },
    {
      title: 'Per Additional User',
      price: '$30',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-pink-100',
    }
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">
          {standardPlans.map(plan => (
            <motion.div
              key={plan.title}
              className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
                <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
                <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
              </div>
              <div className="flex items-center justify-center text-gray-600">
                <FaHdd className="text-blue-600 mr-2" />
                <span>{plan.storage}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const DifferenceSection = () => {
  return (
    <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800 via-blue-500 to-purple-300 opacity-1 "></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">Unlock the Full Potential of QuickBooks Enterprise with ConciseNext</h2>
        <p className="text-lg leading-relaxed mb-8">
          By choosing ConciseNext for your QuickBooks Enterprise hosting, you’re partnering with a provider that understands the unique needs of large businesses. Our advanced cloud hosting solutions are designed to help you maximize the capabilities of QuickBooks Enterprise, driving your business success.
        </p>
        <button className="bg-white text-button font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:scale-105">
          Start Your 7 Day Free Trial
        </button>
      </div>
    </div>
  );
};

const SmoothScrollFeature = ({ feature, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  useEffect(() => {
    if (inView && window.innerWidth > 768) {  // Check if screen is larger than 768px
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={window.innerWidth > 768 ? controls : 'visible'}  // Disable animation on small screens
      variants={{
        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.7, ease: 'easeOut' }}
      className="grid md:grid-cols-2 gap-8 xl:gap-20 items-center py-8"
    >
      {/* Image */}
      <div className={`${index % 2 === 0 ? 'order-last md:order-first' : ''}`}>
        <img
          src={feature.image}
          alt={feature.name}
          className="rounded-lg shadow-lg transform hover:scale-105 h-[auto] w-[400px] xl:h-[auto] xl:w-[600px] transition duration-500"
        />
      </div>
      {/* Text */}
      <div>
        <h3 className="text-2xl font-bold text-gray-800 mb-4">{feature.name}</h3>
        <p className="text-lg text-gray-700 leading-relaxed">{feature.description}</p>
      </div>
    </motion.div>
  );
};


const Hero = () => {
  const { scrollY } = useScroll();
  const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
  const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
  const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
  const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <div className="relative h-[500px] md:h-[600px]  bg-[url('https://concisenext.com/public_image/webp/desk-593327_1280.webp')] bg-cover bg-center bg-no-repeat overflow-hidden">
      {/* Background Circles Effect */}
      <div className="absolute flex items-center justify-center">
        <motion.div
          className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
          style={{ scale: scale1 }}
        />
        <motion.div
          className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
          style={{ scale: scale2 }}
        />
        <motion.div
          className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
          style={{ scale: scale3 }}
        />
      </div>

      {/* Hero Content */}
      <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
        <motion.h1
          className="text-3xl sm:text-4xl md:text-3xl font-bold mb-2 md:mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          QuickBooks Enterprise Hosting
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
          Discover Unrivaled Security, Reliability, and Flexibility Tailored for Accounting Professionals
        </motion.p>
        <motion.a
          href="/free-trial"
          className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
        >
          Start Your 7 Day Free Trial
        </motion.a>
      </div>



    </div>
  );
};

const QuickBooksEnterprise = () => {


  return (
    <div>
      <Helmet>
        <title>QuickBooks Enterprise Hosting with Cloud Access | ConciseNext</title>
        <meta name="title" content="QuickBooks Enterprise Hosting with Cloud Access | ConciseNext" />
        <meta name="focus_keyword" content="QuickBooks Enterprise Hosting" />
        <meta name="description" content="Get secure and scalable QuickBooks Enterprise Hosting on the cloud. At ConciseNext access QuickBooks Cloud Enterprise Hosting anytime, anywhere, with enhanced performance and collaboration." />
        <meta name="keywords" content="concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />

        <meta property="og:title" content="ConciseNext: Your Cloud Application Hosting Solution" />
        <meta property="og:description" content="Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs." />
        <meta property="og:image" content="https://concisenext.com/public_image/webp/logocolor.481479ea418411f3e450.webp" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <Hero />
      <HostingSection />
      <QuickBooksProHostingIntro/>
      <QuickBooksCloudEnterprise />
      <div className="bg-white ">
        <div className="container mx-auto max-w-7xl">
          {features.map((feature, index) => (
            <SmoothScrollFeature key={feature.id} feature={feature} index={index} />
          ))}
        </div>
      </div>

      <DifferenceSection />
      <BenefitsSection />
      <QuickBooksPremierHostingIntro />
      <StandardHostingPrePlan/>
      <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-semibold text-center mb-8">FAQs – QuickBooks Enterprise Hosting</h1>

      <div className="space-y-6">
        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-blue-600">1. How does hosting work for QuickBooks Enterprise?</h2>
          <p className="text-gray-700">
            QuickBooks Enterprise is installed on secure cloud servers, allowing users to access it remotely from any device with an internet connection. This ensures seamless collaboration, automatic updates, and enhanced security.
          </p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-blue-600">2. Can I host multiple versions of QuickBooks Enterprise Accounting Software in the cloud?</h2>
          <p className="text-gray-700">
            Yes, you can host multiple versions of QuickBooks Enterprise, enabling access to different company files or versions as per your business needs.
          </p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-blue-600">3. How often are data backups performed in your cloud hosting solution?</h2>
          <p className="text-gray-700">
            We perform automated backups daily to ensure data integrity and security. Backup retention periods vary based on your hosting plan.
          </p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-blue-600">4. What add-ons or third-party applications can be hosted with QuickBooks Enterprise?</h2>
          <p className="text-gray-700">
            You can integrate QuickBooks Enterprise with various add-ons, including payroll, CRM, inventory management, and payment processing applications, ensuring a seamless workflow.
          </p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-blue-600">5. How do I choose the best cloud hosting provider for QuickBooks Enterprise?</h2>
          <p className="text-gray-700">
            Look for a provider with high uptime (99.99%+), strong security, scalable hosting options, 24/7 support, and compatibility with QuickBooks add-ons and third-party apps.
          </p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-blue-600">6. What are the key differences between QuickBooks Enterprise Desktop, cloud-hosted QuickBooks Enterprise, and QuickBooks Online?</h2>
          <ul className="list-disc pl-5 text-gray-700">
            <li><strong>QuickBooks Enterprise Desktop:</strong> Installed on a local device, requiring manual updates and backups.</li>
            <li><strong>Cloud-Hosted QuickBooks Enterprise:</strong> Offers full desktop features with remote access, automatic updates, and enhanced security.</li>
            <li><strong>QuickBooks Online:</strong> A web-based version with limited features compared to QuickBooks Enterprise.</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default QuickBooksEnterprise;
